import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';

import {
  moveLayerUp,
  moveLayerDown,
  clearAllObjectSelectStyle,
  deleteObject,
  isExistAnyTextBox,
} from '../../lib';

import { changeModeToEdit, toSelectObject } from '../../states/actions';

import editTextIcon from '../../assets/icon/edit_text.png';
import layersUpIcon from '../../assets/icon/layers_up.png';
import layersDownIcon from '../../assets/icon/layers_down.png';
import deleteIcon from '../../assets/icon/delete.png';

const RenderEditingHeader = ({
  changeModeToEdit: changeModeToEditFromProps,
  toSelectObject: toSelectObjectFromProps,
  currentTool,
  selectObjectId,
  toggleTextModal,
  toggleCancelAlert,
  t,
}) => (
  <Fragment>
    <Col xs={3} className="editorHeader-row-cancelAction">
      <span
        className="editorHeader-row-cancelAction-text"
        onClick={toggleCancelAlert}
      >
        {t('dialog_backtheme_button_back')}
      </span>
    </Col>
    <Col xs={6} className="editorHeader-row-editTools">
      {currentTool === 'TEXT' && (
        <img
          src={editTextIcon}
          alt="editTextIcon"
          className="objectTool"
          onClick={() => {
            if (selectObjectId) toggleTextModal(selectObjectId);
          }}
        />
      )}
      {currentTool !== 'BACKGROUND' && (
        <Fragment>
          <img
            src={layersUpIcon}
            alt="layersUpIcon"
            className="objectTool"
            onClick={() => {
              if (selectObjectId) moveLayerUp(selectObjectId);
            }}
          />
          <img
            src={layersDownIcon}
            alt="layersDownIcon"
            className="objectTool"
            onClick={() => {
              if (selectObjectId) moveLayerDown(selectObjectId);
            }}
          />
          <img
            src={deleteIcon}
            alt="deleteIcon"
            className="objectTool"
            onClick={() => {
              if (selectObjectId) {
                deleteObject(selectObjectId, toSelectObjectFromProps);
                if (currentTool === 'TEXT' && !isExistAnyTextBox()) {
                  changeModeToEditFromProps(false);
                }
              }
            }}
          />
        </Fragment>
      )}
    </Col>
    <Col xs={3} className="editorHeader-row-finishAction">
      <span
        className="editorHeader-row-finishAction-text"
        onClick={() => {
          changeModeToEditFromProps(false);
          clearAllObjectSelectStyle(toSelectObjectFromProps);
        }}
      >
        {t('edit_button_complete')}
      </span>
    </Col>
  </Fragment>
);

RenderEditingHeader.propTypes = {
  changeModeToEdit: PropTypes.func.isRequired,
  currentTool: PropTypes.string.isRequired,
  selectObjectId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  toSelectObject: PropTypes.func.isRequired,
  toggleCancelAlert: PropTypes.func.isRequired,
  toggleTextModal: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(
    state => ({
      ...state.tool,
      ...state.object,
    }),
    { changeModeToEdit, toSelectObject }
  )(RenderEditingHeader)
);
