import {
  getThemeObjects,
  getSpecificBackground,
  allWallpapers,
} from './defaultThemes';

import { getCorrespondBackgrounds } from './backgrounds';

export const defaultWallpapersNumber = 3;

const wallpapers = [
  {
    id: 0,
    source: {
      backgrounds: getCorrespondBackgrounds(0),
      specificBackground: getSpecificBackground(allWallpapers[0]),
      stickers: getThemeObjects(allWallpapers[0]),
    },
  },
  {
    id: 1,
    source: {
      backgrounds: getCorrespondBackgrounds(1),
      specificBackground: getSpecificBackground(allWallpapers[1]),
      stickers: getThemeObjects(allWallpapers[1]),
    },
  },
  {
    id: 2,
    source: {
      backgrounds: getCorrespondBackgrounds(2),
      specificBackground: getSpecificBackground(allWallpapers[2]),
      stickers: getThemeObjects(allWallpapers[2]),
    },
  },
];

const userWallpaper = {};

export const createUserTheme = domTree => {
  const specificBackground = getSpecificBackground(domTree);
  const backgrounds = getCorrespondBackgrounds(
    parseInt(specificBackground.themeId, 10)
  );
  const stickers = getThemeObjects(domTree);
  userWallpaper.id = wallpapers.length + 1;
  userWallpaper.source = {
    backgrounds,
    specificBackground,
    stickers,
  };

  wallpapers.push(userWallpaper);
  console.log(wallpapers);

  return userWallpaper;
};

export default wallpapers;
