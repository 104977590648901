const dragToResizeOffset = 4;
const initialX = 95;
const initialY = 180;

export const setCornerScale = (corner, scale) => {
  const scaleReverse = 1 / scale;
  const commandScale = `scale(${scaleReverse})`;
  corner.style.webkitTransform = commandScale;
  corner.style.transform = commandScale;
};

export const setInitialPosition = target => {
  const command = `translate(${initialX}px, ${initialY}px)`;
  target.style.webkitTransform = command;
  target.style.transform = command;

  target.setAttribute('data-x', initialX);
  target.setAttribute('data-y', initialY);
};

export const dragMove = event => {
  const { target } = event;
  const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
  const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
  const scale = parseFloat(target.getAttribute('scale')) || 1;
  const angle = parseFloat(target.dataset.angle) || 0;

  const command = `translate(${x}px, ${y}px) rotate(${angle}rad) scale(${scale})`;
  target.style.webkitTransform = command;
  target.style.transform = command;

  const corner = target.querySelector('div');
  setCornerScale(corner, scale);

  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
};

export const getDragAngle = event => {
  const { target } = event;
  const startAngle = parseFloat(target.dataset.angle) || 0;
  const center = {
    x: parseFloat(target.dataset.centerX) || 0,
    y: parseFloat(target.dataset.centerY) || 0,
  };
  const angle = Math.atan2(center.y - event.clientY, center.x - event.clientX);
  return angle - startAngle;
};

export const getDragDistanceFromCenter = event => {
  const { target } = event;
  const center = {
    x: parseFloat(target.dataset.centerX) || 0,
    y: parseFloat(target.dataset.centerY) || 0,
  };
  const distanceFromCenter = Math.hypot(
    center.y - event.clientY,
    center.x - event.clientX
  );
  return distanceFromCenter;
};

export const setCenter = target => {
  const rect = target.getBoundingClientRect();
  target.dataset.centerX = rect.left + rect.width / 2;
  target.dataset.centerY = rect.top + rect.height / 2;
};

export const startDragAngle = event => {
  const { target } = event;
  setCenter(target);
  target.dataset.angle = getDragAngle(event);
};

export const endDragAngle = event => {
  const { target } = event;
  target.dataset.angle = getDragAngle(event);
};

export const startDragDistanceFromCenter = event => {
  const { target } = event;
  setCenter(target);
  if (!target.dataset.initialDistanceFromCenter) {
    const rect = target.getBoundingClientRect();
    const rightCornerFromCenter = Math.hypot(rect.width / 2, rect.height / 2);
    target.dataset.initialDistanceFromCenter =
      rightCornerFromCenter - dragToResizeOffset;
  }
};

const getDegreeByAngle = angle => angle * (180 / Math.PI);

export const resizeMove = event => {
  const { target } = event;

  const dataX = parseFloat(target.getAttribute('data-x')) || 0;
  const dataY = parseFloat(target.getAttribute('data-y')) || 0;
  const angle = getDragAngle(event);
  const degree = Math.round(getDegreeByAngle(angle), 10);
  const scaleAttribute = parseFloat(target.getAttribute('scale')) || 1;

  if (degree % 90 === 0) {
    target.style.outline = `${3 /
      scaleAttribute}px solid rgba(255, 255, 255, 0.5)`;
  } else {
    target.style.outline = '1px solid transparent';
  }

  const distanceFromCenter = getDragDistanceFromCenter(event);
  const scale =
    distanceFromCenter / parseFloat(target.dataset.initialDistanceFromCenter);

  const command = `translate(${dataX}px, ${dataY}px) rotate(${angle}rad) scale(${scale})`;
  target.style.webkitTransform = command;
  target.style.transform = command;

  const corner = target.querySelector('div');
  setCornerScale(corner, scale);

  target.setAttribute('scale', scale);
};
