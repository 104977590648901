import PropTypes from 'prop-types';
import React from 'react';

import './SelectWallpaper.scss';

import { initialSelectBoxShadow } from '../../lib';

const SelectWallpaper = ({ wallpapers, onChangeWallpaper, previewImages }) => (
  <div className="selectWallpaper">
    <div className="selectWallpaper-gallery">
      {wallpapers.map(wallpaper => (
        <div
          key={wallpaper.id}
          id={wallpaper.id}
          className="imageToBeSelected"
          style={{
            boxShadow: initialSelectBoxShadow(wallpaper.id, 0),
            backgroundImage: `url(${previewImages[wallpaper.id]})`,
            backgroundSize: 'cover',
          }}
          onClick={() => onChangeWallpaper(wallpaper.id)}
        />
      ))}
    </div>
  </div>
);

SelectWallpaper.propTypes = {
  previewImages: PropTypes.array.isRequired,
  wallpapers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeWallpaper: PropTypes.func.isRequired,
};

export default SelectWallpaper;
