import {
  Theme1_Sticker_1,
  Theme1_Sticker_2,
  Theme1_Sticker_3,
  Theme1_Sticker_4,
  Theme1_Sticker_5,
  Theme1_Sticker_6,
  Theme1_Sticker_7,
  Theme1_Sticker_8,
  Theme1_Sticker_9,
  Theme1_Sticker_10,
  Theme1_Sticker_11,
  Theme1_Sticker_12,
  Theme1_Sticker_13,
  Theme1_Sticker_14,
  Theme1_Sticker_15,
  Theme1_Sticker_16,
} from './stickersExport/theme1';
import {
  Theme2_Sticker_1,
  Theme2_Sticker_2,
  Theme2_Sticker_3,
  Theme2_Sticker_4,
  Theme2_Sticker_5,
  Theme2_Sticker_6,
  Theme2_Sticker_7,
  Theme2_Sticker_8,
  Theme2_Sticker_9,
  Theme2_Sticker_10,
  Theme2_Sticker_11,
  Theme2_Sticker_12,
  Theme2_Sticker_13,
  Theme2_Sticker_14,
  Theme2_Sticker_15,
  Theme2_Sticker_16,
  Theme2_Sticker_17,
  Theme2_Sticker_18,
  Theme2_Sticker_19,
  Theme2_Sticker_20,
  Theme2_Sticker_21,
  Theme2_Sticker_22,
  Theme2_Sticker_23,
  Theme2_Sticker_24,
  Theme2_Sticker_25,
  Theme2_Sticker_26,
  Theme2_Sticker_27,
  Theme2_Sticker_28,
  Theme2_Sticker_29,
  Theme2_Sticker_30,
  Theme2_Sticker_31,
  Theme2_Sticker_32,
  Theme2_Sticker_33,
  Theme2_Sticker_34,
  Theme2_Sticker_35,
} from './stickersExport/theme2';
import {
  Theme3_Sticker_1,
  Theme3_Sticker_2,
  Theme3_Sticker_3,
  Theme3_Sticker_4,
  Theme3_Sticker_5,
  Theme3_Sticker_6,
  Theme3_Sticker_7,
  Theme3_Sticker_8,
  Theme3_Sticker_9,
  Theme3_Sticker_10,
  Theme3_Sticker_11,
  Theme3_Sticker_12,
  Theme3_Sticker_13,
  Theme3_Sticker_14,
  Theme3_Sticker_15,
  Theme3_Sticker_16,
} from './stickersExport/theme3';

let currentThemeStickerID = -1;

const generateThemeStickerId = () => {
  currentThemeStickerID = currentThemeStickerID + 1;
  return `themeSticker${currentThemeStickerID}`;
};

const stickerTools = [
  [
    { id: generateThemeStickerId(), source: Theme1_Sticker_1 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_2 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_3 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_4 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_5 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_6 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_7 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_8 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_9 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_10 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_11 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_12 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_13 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_14 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_15 },
    { id: generateThemeStickerId(), source: Theme1_Sticker_16 },
  ],
  [
    { id: generateThemeStickerId(), source: Theme2_Sticker_1 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_2 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_3 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_4 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_5 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_6 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_7 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_8 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_9 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_10 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_11 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_12 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_13 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_14 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_15 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_16 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_17 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_18 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_19 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_20 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_21 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_22 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_23 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_24 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_25 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_26 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_27 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_28 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_29 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_30 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_31 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_32 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_33 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_34 },
    { id: generateThemeStickerId(), source: Theme2_Sticker_35 },
  ],
  [
    { id: generateThemeStickerId(), source: Theme3_Sticker_1 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_2 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_3 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_4 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_5 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_6 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_7 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_8 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_9 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_10 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_11 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_12 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_13 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_14 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_15 },
    { id: generateThemeStickerId(), source: Theme3_Sticker_16 },
  ],
];

export default stickerTools;
