import { CHANGE_TOOL } from '../actionTypes';

const toolsTypes = ['BACKGROUND', 'TEXT', 'STICKER', 'NONE'];

const initialState = {
  currentTool: toolsTypes[3],
};

const tool = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TOOL: {
      const { id } = action.payload;
      return {
        ...state,
        currentTool: toolsTypes[id],
      };
    }
    default:
      return state;
  }
};

export default tool;
