import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import { setLangOnBody } from '../../lib/other';
import './ChangeLanguage.scss';

export const languages = [
  'de',
  'en',
  'es',
  'fr',
  'ja',
  'ko',
  'ru',
  'zh-CN',
  'zh-TW',
];

const ChangeLanguage = ({ i18n }) => (
  <div
    className="changeLanguage"
    style={{
      position: 'absolute',
      bottom: '0',
      right: '0',
    }}
  >
    {languages.map(language => (
      <button
        type="button"
        key={language}
        onClick={() => {
          i18n.changeLanguage(language);
          setLangOnBody(i18n.language);
        }}
      >
        {language}
      </button>
    ))}
  </div>
);

ChangeLanguage.propTypes = {
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(ChangeLanguage);
