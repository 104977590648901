import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Tooltip } from 'reactstrap';
import ClipboardJS from 'clipboard';
import interact from 'interactjs';

import SaveHeader from './SaveHeader';

import './index.scss';
import commonBackIcon from '../../assets/icon/common_back.png';
import { getCurrentBaseUrl, setOutputImageMargin } from '../../lib/server';
import { workingAreaID } from '../../lib';

class SavePage extends Component {
  state = {
    tooltipOpen: false,
    holdImage: false,
  };

  async componentDidMount() {
    const clipboard = new ClipboardJS('#copyButton');
    clipboard.on('success', e => {
      console.log(`Copy : ${e.text}`);
      e.clearSelection();
    });

    interact('#outputImage').on('hold', () => {
      this.setState({ holdImage: true });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { shareImageId } = this.props;
    if (prevProps.shareImageId !== shareImageId) {
      const lang = document.body.getAttribute('lang');
      const newurl = `${getCurrentBaseUrl()}?id=${shareImageId}&locale=${lang}`;
      window.history.pushState({ path: newurl }, '', newurl);
    }

    if (this.state.holdImage && !prevState.holdImage) {
      window.dataLayer.push({ event: 'btn_savewp_save' });
    }
  }

  openTooltip = () => {
    this.setState({
      tooltipOpen: true,
    });
  };

  closeTooltip = () => {
    this.setState({
      tooltipOpen: false,
    });
  };

  backToEditingState = () => {
    this.closeTooltip();
    const newurl = getCurrentBaseUrl();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  render() {
    const { renderOutputPage, imageUrl, setPreviewImageUrl, t } = this.props;
    setOutputImageMargin();
    const sentences = t('share_caption').split('\n');

    return (
      <div className="savePage">
        <div
          onClick={() => {
            setPreviewImageUrl('');
            renderOutputPage(false);
            this.closeTooltip();
            this.backToEditingState();

            // 在輸出時先把動畫都拿掉了，回去編輯器要加回來
            const workingArea = document.getElementById(workingAreaID);
            workingArea.style.transition = 'transform 0.5s';
          }}
          className="savePage-backAction"
        >
          <img src={commonBackIcon} alt="commonBackIcon" />
        </div>
        <SaveHeader />
        <img
          src={imageUrl}
          id="outputImage"
          alt="outputImage"
          className="savePage-image"
        />
        <Container className="savePage-bottomRow">
          <Row className="justify-content-center align-items-center">
            <Col xs={12}>
              <div className="savePage-bottomRow-text">
                {sentences.map((sentence, index) => {
                  if (index !== sentences.length - 1) {
                    return (
                      <span key={index}>
                        {sentence}
                        <br />
                      </span>
                    );
                  }
                  return <span key={index}>{sentence}</span>;
                })}
              </div>
            </Col>
            <Col xs={5}>
              <button
                id="copyButton"
                type="button"
                className="savePage-bottomRow-button"
                data-clipboard-text={`${window.location.href}`}
              >
                {t('share_button_copylink')}
              </button>
            </Col>
          </Row>
        </Container>
        <Tooltip
          className="savePage-toolTip"
          placement="right"
          target="copyButton"
          isOpen={this.state.tooltipOpen}
          toggle={this.openTooltip}
        >
          {t('copylink_caption')}
        </Tooltip>
      </div>
    );
  }
}

SavePage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  renderOutputPage: PropTypes.func.isRequired,
  setPreviewImageUrl: PropTypes.func.isRequired,
  shareImageId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SavePage);
