import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';

import { toSelectObject, changeModeToEdit } from '../../states/actions';
import {
  createTextBox,
  editTextBox,
  fillTextBoxModalValue,
  isExistAnyTextBox,
} from '../../lib';
import './TextBoxModal.scss';

const TextBoxModal = ({
  isOpen,
  toggle,
  selectTextBoxId,
  toSelectObject: toSelectObjectFromProps,
  changeModeToEdit: changeModeToEditFromProps,
  t,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={() => {
      toggle();
      if (!isExistAnyTextBox()) {
        changeModeToEditFromProps(false);
      }
    }}
    autoFocus
    onOpened={() => {
      if (selectTextBoxId) fillTextBoxModalValue(selectTextBoxId);
    }}
    className="textBoxModal"
    modalClassName="textBoxModal-body"
    contentClassName="textBoxModal-body-content"
  >
    <ModalBody>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} className="textBoxModal-body-content-title">
            {t('dialog_text_title')}
          </Col>
          <Col xs={12} className="textBoxModal-body-content-textarea">
            <textarea
              id="userInput"
              placeholder={t('dialog_text_placeholder')}
              className="textBoxModal-body-content-textarea-form"
              onFocus={() => {
                const userInput = document.getElementById('userInput');
                userInput.style.borderColor = '#828282';
              }}
              spellCheck={false}
            />
          </Col>
          <Col xs={12} className="textBoxModal-body-content-message">
            {t('dialog_text_caption')}
          </Col>
          <Col xs={12} className="textBoxModal-body-content-action">
            <div
              onClick={() => {
                const input = document.getElementById('userInput').value;
                if (input) {
                  toggle();
                  if (!selectTextBoxId) {
                    createTextBox(input, toSelectObjectFromProps);
                  } else {
                    editTextBox(input, selectTextBoxId);
                  }
                } else {
                  const userInput = document.getElementById('userInput');
                  userInput.style.borderColor = '#dc3545';
                }
              }}
            >
              {t('dialog_text_button_confirm')}
            </div>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  </Modal>
);

TextBoxModal.defaultProps = {
  selectTextBoxId: '',
};

TextBoxModal.propTypes = {
  changeModeToEdit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectTextBoxId: PropTypes.string,
  t: PropTypes.func.isRequired,
  toSelectObject: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(
    () => ({}),
    { changeModeToEdit, toSelectObject }
  )(TextBoxModal)
);
