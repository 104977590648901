import React, { Suspense } from 'react';
import {
  // BrowserRouter as Router,
  MemoryRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import DesktopPage from './components/DesktopPage';
import HomePage from './components/HomePage';
import SelectPage from './components/SelectPage';
import EditorPage from './components/EditorPage';

import { isMobile } from './index';

const App = () => (
  <Suspense fallback={<div />}>
    <Router>
      {isMobile ? (
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/select-wallpaper" component={SelectPage} />
          <Route path="/editor/:wallpaperId" component={EditorPage} />
          <Redirect to="/" />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/" component={DesktopPage} />
          <Redirect to="/" />
        </Switch>
      )}
    </Router>
  </Suspense>
);

export default App;
