import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import { languages } from '../Languages/ChangeLanguage';
import { parseDomToJson, workingAreaID } from '../../lib';
import './PreviewImage.scss';

import previewImageDE from '../../assets/previewImages/de.png';
import previewImageEN from '../../assets/previewImages/en.png';
import previewImageES from '../../assets/previewImages/es.png';
import previewImageFR from '../../assets/previewImages/fr.png';
import previewImageJA from '../../assets/previewImages/ja.png';
import previewImageKO from '../../assets/previewImages/ko.png';
import previewImageRU from '../../assets/previewImages/ru.png';
import previewImageCN from '../../assets/previewImages/zh-CN.png';
import previewImageTW from '../../assets/previewImages/zh-TW.png';

const selectBackgroundImage = lang => {
  switch (lang) {
    case languages[0]: {
      return previewImageDE;
    }
    case languages[1]: {
      return previewImageEN;
    }
    case languages[2]: {
      return previewImageES;
    }
    case languages[3]: {
      return previewImageFR;
    }
    case languages[4]: {
      return previewImageJA;
    }
    case languages[5]: {
      return previewImageKO;
    }
    case languages[6]: {
      return previewImageRU;
    }
    case languages[7]: {
      return previewImageCN;
    }
    case languages[8]: {
      return previewImageTW;
    }
    default:
      return '';
  }
};

const PreviewImage = ({ i18n, uploadWallpaperInfomation, previewImageUrl }) => (
  <div
    id="previewImageAreaID"
    style={{
      backgroundImage: `url(${selectBackgroundImage(i18n.language)})`,
      backgroundSize: 'cover',
    }}
  >
    <img
      id="previewImageID"
      src={previewImageUrl}
      alt="previewImageID"
      onLoad={async () => {
        const wallpaper = document.getElementById(workingAreaID);
        const json = parseDomToJson(wallpaper);
        await uploadWallpaperInfomation({ json });
      }}
    />
  </div>
);

PreviewImage.propTypes = {
  i18n: PropTypes.object.isRequired,
  previewImageUrl: PropTypes.string.isRequired,
  uploadWallpaperInfomation: PropTypes.func.isRequired,
};

export default withTranslation()(PreviewImage);
