import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import { changeModeToEdit, changeTool } from '../../states/actions';
import './ToolBox.scss';

import backgroundIcon from '../../assets/icon/background.png';
import textIcon from '../../assets/icon/text.png';
import stickerIcon from '../../assets/icon/sticker.png';

import RenderEditingToolBox from './RenderEditingToolBox';

const ToolBox = ({
  changeModeToEdit: changeModeToEditFromProps,
  changeTool: changeToolFromProps,
  isEditingMode,
  toggleTextModal,
  toggleStickerModal,
  stickersThemeId,
  wallpaperId,
  t,
}) => (
  <Container id="toolBoxID" className="toolbox">
    <Row className="justify-content-between">
      {!isEditingMode ? (
        <Fragment>
          <Col xs={4} className="toolbox-col">
            <div
              className="toolbox-col-background"
              onClick={() => {
                changeModeToEditFromProps(true);
                changeToolFromProps(0);
              }}
            >
              <img
                src={backgroundIcon}
                alt="backgroundIcon"
                className="toolbox-col-background"
              />
              {t('main_button_background')}
            </div>
          </Col>
          <Col xs={4} className="toolbox-col">
            <div
              className="toolbox-col-text"
              onClick={() => {
                toggleTextModal();
                changeModeToEditFromProps(true);
                changeToolFromProps(1);
              }}
            >
              <img src={textIcon} alt="textIcon" className="toolbox-col-text" />
              {t('main_button_text')}
            </div>
          </Col>
          <Col xs={4} className="toolbox-col">
            <div
              className="toolbox-col-sticker"
              onClick={() => {
                changeModeToEditFromProps(true);
                changeToolFromProps(2);
              }}
            >
              <img
                src={stickerIcon}
                alt="stickerIcon"
                className="toolbox-col-sticker"
              />
              {t('main_button_sticker')}
            </div>
          </Col>
        </Fragment>
      ) : (
        <Col className="toolbox-editCol">
          <RenderEditingToolBox
            toggleStickerModal={toggleStickerModal}
            stickersThemeId={stickersThemeId}
            wallpaperId={wallpaperId}
          />
        </Col>
      )}
    </Row>
  </Container>
);

ToolBox.propTypes = {
  changeModeToEdit: PropTypes.func.isRequired,
  changeTool: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
  stickersThemeId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  toggleStickerModal: PropTypes.func.isRequired,
  toggleTextModal: PropTypes.func.isRequired,
  wallpaperId: PropTypes.number.isRequired,
};

export default withTranslation()(
  connect(
    state => ({
      ...state.mode,
      ...state.object,
    }),
    { changeModeToEdit, changeTool }
  )(ToolBox)
);
