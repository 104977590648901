import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import MobileDetect from 'mobile-detect';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './states/store';

import './i18n';

export const designWidth = 375;
export const designHeight = 667;

// 代表輸出圖片的實際尺寸 ( 375 * 667 的兩倍，在 retina 螢幕上也清晰 )
export const outputImageWidth = designWidth * 2;
document.documentElement.style.setProperty(
  '--outputImageWidth',
  outputImageWidth
);
export const outputImageHeight = designHeight * 2;
document.documentElement.style.setProperty(
  '--outputImageHeight',
  outputImageHeight
);

// window.addEventListener('resize', () => {
//   document.body.append(`${window.innerHeight} ,`);
// });

const md = new MobileDetect(window.navigator.userAgent);
export const isMobile = md.mobile();

// 非 667 因為我們不算瀏覽器上排 & 底下工具列
const deaultPhoneHeight = 549;
const useragent = navigator.userAgent || navigator.vendor || window.opera;
let mobileHeight;

const isFacebookApp = () =>
  useragent.indexOf('FBAN') > -1 || useragent.indexOf('FBAV') > -1;

const isInstagramApp = () => useragent.indexOf('Instagram') > -1;

// document.body.append(`${md.os()}\n`);

if (md.os() === 'iOS' && (isFacebookApp() || isInstagramApp())) {
  // document.body.append(
  //   `isFacebookApp: ${isFacebookApp()}, isInstagramApp: ${isInstagramApp()}`
  // );

  let heightOffset;

  document.documentElement.style.setProperty('--vh', '1vh');
  if (isFacebookApp()) {
    heightOffset = 30;
  } else if (isInstagramApp()) {
    heightOffset = 50;
  }

  mobileHeight = deaultPhoneHeight + heightOffset;
} else {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  mobileHeight = window.innerHeight;
}

// 都以 375 * 549 為基礎，尺寸與此不同，會再去 scale 它
export const mobileScale = mobileHeight / deaultPhoneHeight;
document.documentElement.style.setProperty('--mobileScale', mobileScale);

export const savePageScale = 0.86;
document.documentElement.style.setProperty('--savePageScale', savePageScale);

export const resultPageScale = 0.75;
document.documentElement.style.setProperty(
  '--resultPageScale',
  resultPageScale
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
