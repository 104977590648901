import React from 'react';

import { languages } from './ChangeLanguage';

import MainNameDE from '../../assets/other/main_name_locale/de.png';
import MainNameEN from '../../assets/other/main_name_locale/en.png';
import MainNameES from '../../assets/other/main_name_locale/es.png';
import MainNameFR from '../../assets/other/main_name_locale/fr.png';
import MainNameJA from '../../assets/other/main_name_locale/ja.png';
import MainNameKO from '../../assets/other/main_name_locale/ko.png';
import MainNameRU from '../../assets/other/main_name_locale/ru.png';
import MainNameCN from '../../assets/other/main_name_locale/zh-CN.png';
import MainNameTW from '../../assets/other/main_name_locale/zh-TW.png';

const renderMainName = lang => {
  switch (lang) {
    case languages[0]: {
      return <img src={MainNameDE} alt="MainNameDE" />;
    }
    case languages[1]: {
      return <img src={MainNameEN} alt="MainNameEN" />;
    }
    case languages[2]: {
      return <img src={MainNameES} alt="MainNameES" />;
    }
    case languages[3]: {
      return <img src={MainNameFR} alt="MainNameFR" />;
    }
    case languages[4]: {
      return <img src={MainNameJA} alt="MainNameJA" />;
    }
    case languages[5]: {
      return <img src={MainNameKO} alt="MainNameKO" />;
    }
    case languages[6]: {
      return <img src={MainNameRU} alt="MainNameRU" />;
    }
    case languages[7]: {
      return <img src={MainNameCN} alt="MainNameCN" />;
    }
    case languages[8]: {
      return <img src={MainNameTW} alt="MainNameTW" />;
    }
    default:
      return (
        <div>
          Language:&nbsp;
          {lang}
        </div>
      );
  }
};

export default renderMainName;
