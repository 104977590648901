import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  clearAllObjectSelectStyle,
  changeSelectTextColor,
  copyStickerAndAppend,
  initialSelectBoxShadow,
} from '../../lib';

import { toSelectObject, toSelectBackground } from '../../states/actions';

import './ToolBox.scss';

import stickerMoreIcon from '../../assets/icon/sticker_more.png';
import stickerTools from '../stickerTools';
import textTools from '../textTools';
import wallpapers from '../wallpapers';

const RenderEditingToolBox = ({
  currentTool,
  selectObjectId,
  stickersThemeId,
  wallpaperId,
  selectBackgroundId,
  toggleStickerModal,
  toSelectObject: toSelectObjectFromProps,
  toSelectBackground: toSelectBackgroundFromProps,
  t,
}) => {
  switch (currentTool) {
    case 'BACKGROUND': {
      return (
        <div className="toolbox-editCol-editingBackgrounds">
          <div className="toolbox-editCol-editingBackgrounds-flex">
            {wallpapers[wallpaperId].source.backgrounds.map(
              (background, index) => (
                <div
                  key={index}
                  id={index}
                  className="imageToBeSelected"
                  style={{
                    boxShadow: initialSelectBoxShadow(
                      index,
                      selectBackgroundId
                    ),
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                  }}
                  onClick={() => {
                    toSelectBackgroundFromProps(index);
                  }}
                />
              )
            )}
          </div>
        </div>
      );
    }
    case 'TEXT': {
      return (
        <div className="toolbox-editCol-editingText">
          <div className="toolbox-editCol-editingText-flex">
            {textTools.map(textTool => (
              <span
                key={textTool.id}
                className="toolbox-editCol-editingText-flex-colorCircle"
                style={{ backgroundColor: textTool.source }}
                onClick={() => {
                  changeSelectTextColor({
                    color: textTool.source,
                    targetId: selectObjectId,
                  });
                }}
              />
            ))}
          </div>
        </div>
      );
    }
    case 'STICKER': {
      return (
        <div className="toolbox-editCol-editingSticker">
          <div className="toolbox-editCol-editingSticker-flex">
            <div
              onClick={() => {
                toggleStickerModal();
                clearAllObjectSelectStyle(toSelectObjectFromProps);
              }}
            >
              <div className="toolbox-editCol-editingSticker-flex-block">
                <img src={stickerMoreIcon} alt="stickerMoreIcon" />
                <span>{t('edit_button_moresticker')}</span>
              </div>
            </div>
            {stickerTools[stickersThemeId].map(stickerTool => (
              <div
                key={stickerTool.id}
                id={stickerTool.id}
                className="origin-sticker"
                theme={stickersThemeId}
                onClick={() => {
                  copyStickerAndAppend(stickerTool.id, toSelectObjectFromProps);
                }}
              >
                <stickerTool.source width="100%" height="100%" />
              </div>
            ))}
          </div>
        </div>
      );
    }
    default:
      return <></>;
  }
};

RenderEditingToolBox.propTypes = {
  currentTool: PropTypes.string.isRequired,
  selectBackgroundId: PropTypes.number.isRequired,
  selectObjectId: PropTypes.string.isRequired,
  stickersThemeId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  toSelectBackground: PropTypes.func.isRequired,
  toSelectObject: PropTypes.func.isRequired,
  toggleStickerModal: PropTypes.func.isRequired,
  wallpaperId: PropTypes.number.isRequired,
};

export default withTranslation()(
  connect(
    state => ({
      ...state.tool,
      ...state.object,
      ...state.background,
    }),
    { toSelectObject, toSelectBackground }
  )(RenderEditingToolBox)
);
