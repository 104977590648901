import { combineReducers } from 'redux';
import mode from './mode';
import tool from './tool';
import object from './object';
import background from './background';
import prevstate from './prevstate';

export default combineReducers({
  mode,
  tool,
  object,
  background,
  prevstate,
});
