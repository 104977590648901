import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';

import {
  clearAllObjectSelectStyle,
  workingAreaID,
  deleteAllObjects,
} from '../../lib';
import {
  changeModeToEdit,
  toSelectObject,
  toSelectBackground,
} from '../../states/actions';

import wallpapers, { defaultWallpapersNumber } from '../wallpapers';

import './ClearContentAlert.scss';

const renderWhichAlert = ({
  toggle,
  whichAlert,
  prevSelectObjectsStateHTML,
  prevSelectBackgroundState,
  changeModeToEditFromProps,
  toSelectObjectFromProps,
  toSelectBackgroundFromProps,
  t,
}) => {
  switch (whichAlert) {
    case 1: {
      return (
        <Fragment>
          <ModalBody className="clearContentAlert-body">
            <Container>
              <Row>
                <Col xs={12} className="clearContentAlert-body-content-title">
                  {t('dialog_backtheme_title')}
                </Col>
                <Col
                  xs={12}
                  className="clearContentAlert-body-content-message1"
                >
                  <div>{t('dialog_backtheme_caption')}</div>
                </Col>
                <Col xs={12} className="clearContentAlert-body-content-button">
                  <Row className="justify-content-between">
                    <Col className="clearContentAlert-body-content-button-action1">
                      <span onClick={toggle}>
                        {t('othershare_button_edit')}
                      </span>
                    </Col>
                    <Col className="clearContentAlert-body-content-button-action2">
                      <NavLink to="/select-wallpaper">
                        <span
                          onClick={() => {
                            toggle();
                            deleteAllObjects();
                            if (wallpapers.length > defaultWallpapersNumber) {
                              wallpapers.pop();
                            }
                          }}
                        >
                          {t('dialog_backtheme_button_back')}
                        </span>
                      </NavLink>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Fragment>
      );
    }
    case 2: {
      return (
        <Fragment>
          <ModalBody>
            <Container>
              <Row>
                <Col xs={12} className="clearContentAlert-body-content-title">
                  {t('dialog_clearall_title')}
                </Col>
                <Col
                  xs={12}
                  className="clearContentAlert-body-content-message2"
                >
                  {t('dialog_clearall_caption')}
                </Col>
                <Col xs={12} className="clearContentAlert-body-content-button">
                  <Row className="justify-content-between">
                    <Col className="clearContentAlert-body-content-button-action1">
                      <span onClick={toggle}>
                        {t('dialog_clearall_button_cancel')}
                      </span>
                    </Col>
                    <Col className="clearContentAlert-body-content-button-action2">
                      <span
                        onClick={() => {
                          toggle();
                          deleteAllObjects();
                        }}
                      >
                        {t('dialog_clearall_button_clear')}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Fragment>
      );
    }
    case 3: {
      return (
        <Fragment>
          <ModalBody className="clearContentAlert-body">
            <Container>
              <Row>
                <Col xs={12} className="clearContentAlert-body-content-title">
                  {t('dialog_back_title')}
                </Col>
                <Col
                  xs={12}
                  className="clearContentAlert-body-content-message3"
                >
                  <div>{t('dialog_back_caption')}</div>
                </Col>
                <Col xs={12} className="clearContentAlert-body-content-button">
                  <Row className="justify-content-between">
                    <Col className="clearContentAlert-body-content-button-action1">
                      <span onClick={toggle}>
                        {t('othershare_button_edit')}
                      </span>
                    </Col>
                    <Col className="clearContentAlert-body-content-button-action2">
                      <span
                        onClick={() => {
                          toggle();
                          changeModeToEditFromProps(false);
                          clearAllObjectSelectStyle(toSelectObjectFromProps);
                          document.getElementById(
                            workingAreaID
                          ).innerHTML = prevSelectObjectsStateHTML;
                          toSelectBackgroundFromProps(
                            prevSelectBackgroundState
                          );
                        }}
                      >
                        {t('dialog_backtheme_button_back')}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Fragment>
      );
    }
    default:
      return <></>;
  }
};

const ClearContentAlert = ({
  isOpen,
  toggle,
  whichAlert,
  prevSelectObjectsStateHTML,
  prevSelectBackgroundState,
  changeModeToEdit: changeModeToEditFromProps,
  toSelectObject: toSelectObjectFromProps,
  toSelectBackground: toSelectBackgroundFromProps,
  t,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    backdrop="static"
    className="clearContentAlert"
    modalClassName="clearContentAlert-body"
    contentClassName="clearContentAlert-body-content"
  >
    {renderWhichAlert({
      toggle,
      whichAlert,
      prevSelectObjectsStateHTML,
      prevSelectBackgroundState,
      changeModeToEditFromProps,
      toSelectObjectFromProps,
      toSelectBackgroundFromProps,
      t,
    })}
  </Modal>
);

ClearContentAlert.propTypes = {
  changeModeToEdit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prevSelectBackgroundState: PropTypes.number.isRequired,
  prevSelectObjectsStateHTML: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  toSelectBackground: PropTypes.func.isRequired,
  toSelectObject: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  whichAlert: PropTypes.number.isRequired,
};

export default withTranslation()(
  connect(
    state => ({
      ...state.prevstate,
    }),
    { changeModeToEdit, toSelectObject, toSelectBackground }
  )(ClearContentAlert)
);
