import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import commonCloseIcon from '../../assets/icon/common_close.png';
import './SelectHeader.scss';

const SelectHeader = ({ t, wallpaperId }) => (
  <Container className="selectHeader">
    <Row className="justify-content-between align-items-center selectHeader-row">
      <Col xs={4} className="selectHeader-row-closeAction selectHeader-back">
        <NavLink to="/" className="selectHeader-back">
          <img
            src={commonCloseIcon}
            alt="commonCloseIcon"
            className="selectHeader-back"
          />
        </NavLink>
      </Col>
      <Col xs={4} className="selectHeader-row-nextAction">
        <NavLink
          to={`/editor/${wallpaperId}`}
          onClick={() =>
            window.dataLayer.push({
              event: `btn_export_theme${wallpaperId + 1}`,
            })
          }
        >
          <span>{t('theme_button_next')}</span>
        </NavLink>
      </Col>
    </Row>
  </Container>
);

SelectHeader.propTypes = {
  t: PropTypes.func.isRequired,
  wallpaperId: PropTypes.number.isRequired,
};

export default withTranslation()(SelectHeader);
