import {
  CHANHE_MODE_TO_EDIT,
  CHANGE_TOOL,
  SELECT_OBJECT,
  SELECT_BACKGROUND,
  SET_PREV_OBJECT_STATE,
  SET_PREV_BACKGROUND_STATE,
} from './actionTypes';

export const changeModeToEdit = isEdit => ({
  type: CHANHE_MODE_TO_EDIT,
  payload: { isEdit },
});

export const changeTool = id => ({
  type: CHANGE_TOOL,
  payload: { id },
});

export const toSelectObject = id => ({
  type: SELECT_OBJECT,
  payload: { id },
});

export const toSelectBackground = id => ({
  type: SELECT_BACKGROUND,
  payload: { id },
});

export const toSetPrevObjectsStateHTML = selectObjectStateHTML => ({
  type: SET_PREV_OBJECT_STATE,
  payload: { selectObjectStateHTML },
});

export const toSetPrevBackgroundState = selectBackgroundState => ({
  type: SET_PREV_BACKGROUND_STATE,
  payload: { selectBackgroundState },
});
