import {
  SET_PREV_OBJECT_STATE,
  SET_PREV_BACKGROUND_STATE,
} from '../actionTypes';

const initialState = {
  prevSelectObjectsStateHTML: '',
  prevSelectBackgroundState: 0,
};

const prevstate = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREV_OBJECT_STATE: {
      const { selectObjectStateHTML } = action.payload;
      return {
        ...state,
        prevSelectObjectsStateHTML: selectObjectStateHTML,
      };
    }
    case SET_PREV_BACKGROUND_STATE: {
      const { selectBackgroundState } = action.payload;
      return {
        ...state,
        prevSelectBackgroundState: selectBackgroundState,
      };
    }
    default:
      return state;
  }
};

export default prevstate;
