import Theme1Backgrounds1, {
  ReactComponent as Theme1Backgrounds1_Component,
} from '../assets/themes/theme1/backgrounds/forest_background_1.svg';
import Theme1Backgrounds2, {
  ReactComponent as Theme1Backgrounds2_Component,
} from '../assets/themes/theme1/backgrounds/forest_background_2.svg';
import Theme1Backgrounds3, {
  ReactComponent as Theme1Backgrounds3_Component,
} from '../assets/themes/theme1/backgrounds/forest_background_3.svg';

import Theme2Backgrounds1, {
  ReactComponent as Theme2Backgrounds1_Component,
} from '../assets/themes/theme2/backgrounds/forest_background_1.svg';
import Theme2Backgrounds2, {
  ReactComponent as Theme2Backgrounds2_Component,
} from '../assets/themes/theme2/backgrounds/forest_background_2.svg';
import Theme2Backgrounds3, {
  ReactComponent as Theme2Backgrounds3_Component,
} from '../assets/themes/theme2/backgrounds/forest_background_3.svg';

import Theme3Backgrounds1, {
  ReactComponent as Theme3Backgrounds1_Component,
} from '../assets/themes/theme3/backgrounds/forest_background_1.svg';
import Theme3Backgrounds2, {
  ReactComponent as Theme3Backgrounds2_Component,
} from '../assets/themes/theme3/backgrounds/forest_background_2.svg';
import Theme3Backgrounds3, {
  ReactComponent as Theme3Backgrounds3_Component,
} from '../assets/themes/theme3/backgrounds/forest_background_3.svg';

export const getCorrespondBackgroundComponents = themeId => {
  switch (themeId) {
    case 0: {
      return [
        Theme1Backgrounds1_Component,
        Theme1Backgrounds2_Component,
        Theme1Backgrounds3_Component,
      ];
    }
    case 1: {
      return [
        Theme2Backgrounds1_Component,
        Theme2Backgrounds2_Component,
        Theme2Backgrounds3_Component,
      ];
    }
    case 2: {
      return [
        Theme3Backgrounds1_Component,
        Theme3Backgrounds2_Component,
        Theme3Backgrounds3_Component,
      ];
    }
    default:
      return [];
  }
};

export const getCorrespondBackgrounds = themeId => {
  switch (themeId) {
    case 0: {
      return [Theme1Backgrounds1, Theme1Backgrounds2, Theme1Backgrounds3];
    }
    case 1: {
      return [Theme2Backgrounds1, Theme2Backgrounds2, Theme2Backgrounds3];
    }
    case 2: {
      return [Theme3Backgrounds1, Theme3Backgrounds2, Theme3Backgrounds3];
    }
    default:
      return [];
  }
};
