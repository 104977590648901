import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';

import './SaveHeader.scss';

const SaveHeader = ({ t }) => (
  <Container className="saveHeader">
    <Row className="justify-content-center align-items-center saveHeader-row">
      <Col xs={6} className="saveHeader-row-text">
        {t('share_title')}
      </Col>
    </Row>
  </Container>
);

SaveHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SaveHeader);
