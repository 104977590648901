import { languages } from './ChangeLanguage';

import Theme1PreviewDE from '../../assets/themesPreview/de/theme1.png';
import Theme2PreviewDE from '../../assets/themesPreview/de/theme2.png';
import Theme3PreviewDE from '../../assets/themesPreview/de/theme3.png';

import Theme1PreviewEN from '../../assets/themesPreview/en/theme1.png';
import Theme2PreviewEN from '../../assets/themesPreview/en/theme2.png';
import Theme3PreviewEN from '../../assets/themesPreview/en/theme3.png';

import Theme1PreviewES from '../../assets/themesPreview/es/theme1.png';
import Theme2PreviewES from '../../assets/themesPreview/es/theme2.png';
import Theme3PreviewES from '../../assets/themesPreview/es/theme3.png';

import Theme1PreviewFR from '../../assets/themesPreview/fr/theme1.png';
import Theme2PreviewFR from '../../assets/themesPreview/fr/theme2.png';
import Theme3PreviewFR from '../../assets/themesPreview/fr/theme3.png';

import Theme1PreviewJA from '../../assets/themesPreview/ja/theme1.png';
import Theme2PreviewJA from '../../assets/themesPreview/ja/theme2.png';
import Theme3PreviewJA from '../../assets/themesPreview/ja/theme3.png';

import Theme1PreviewKO from '../../assets/themesPreview/ko/theme1.png';
import Theme2PreviewKO from '../../assets/themesPreview/ko/theme2.png';
import Theme3PreviewKO from '../../assets/themesPreview/ko/theme3.png';

import Theme1PreviewRU from '../../assets/themesPreview/ru/theme1.png';
import Theme2PreviewRU from '../../assets/themesPreview/ru/theme2.png';
import Theme3PreviewRU from '../../assets/themesPreview/ru/theme3.png';

import Theme1PreviewCN from '../../assets/themesPreview/zh-CN/theme1.png';
import Theme2PreviewCN from '../../assets/themesPreview/zh-CN/theme2.png';
import Theme3PreviewCN from '../../assets/themesPreview/zh-CN/theme3.png';

import Theme1PreviewTW from '../../assets/themesPreview/zh-TW/theme1.png';
import Theme2PreviewTW from '../../assets/themesPreview/zh-TW/theme2.png';
import Theme3PreviewTW from '../../assets/themesPreview/zh-TW/theme3.png';

const renderWallpaperImage = lang => {
  switch (lang) {
    case languages[0]: {
      return [Theme1PreviewDE, Theme2PreviewDE, Theme3PreviewDE];
    }
    case languages[1]: {
      return [Theme1PreviewEN, Theme2PreviewEN, Theme3PreviewEN];
    }
    case languages[2]: {
      return [Theme1PreviewES, Theme2PreviewES, Theme3PreviewES];
    }
    case languages[3]: {
      return [Theme1PreviewFR, Theme2PreviewFR, Theme3PreviewFR];
    }
    case languages[4]: {
      return [Theme1PreviewJA, Theme2PreviewJA, Theme3PreviewJA];
    }
    case languages[5]: {
      return [Theme1PreviewKO, Theme2PreviewKO, Theme3PreviewKO];
    }
    case languages[6]: {
      return [Theme1PreviewRU, Theme2PreviewRU, Theme3PreviewRU];
    }
    case languages[7]: {
      return [Theme1PreviewCN, Theme2PreviewCN, Theme3PreviewCN];
    }
    case languages[8]: {
      return [Theme1PreviewTW, Theme2PreviewTW, Theme3PreviewTW];
    }
    default:
      return [Theme1PreviewTW, Theme2PreviewTW, Theme3PreviewTW];
  }
};

export default renderWallpaperImage;
