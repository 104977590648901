import { CHANHE_MODE_TO_EDIT } from '../actionTypes';

const initialState = {
  isEditingMode: false,
};

const mode = (state = initialState, action) => {
  switch (action.type) {
    case CHANHE_MODE_TO_EDIT: {
      const { isEdit } = action.payload;
      return {
        ...state,
        isEditingMode: isEdit,
      };
    }
    default:
      return state;
  }
};

export default mode;
