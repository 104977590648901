import ReactDOMServer from 'react-dom/server';
import { parse, stringify } from 'himalaya';

import stickerTools from '../components/stickerTools';
import { setSVGWidthHeight } from './other';

export const stringifyJsonToDom = json => {
  const dom = stringify(json);

  const tempWrapper = document.createElement('div');
  tempWrapper.innerHTML = dom;
  const childs = tempWrapper.getElementsByTagName('div');

  for (let i = 0; i < childs.length; i = i + 1) {
    const svgStickerId = childs[i].getAttribute('svgStickerId');

    if (svgStickerId) {
      let svgSource;

      for (
        let themeIndex = 0;
        themeIndex < stickerTools.length;
        themeIndex = themeIndex + 1
      ) {
        const targetSVG = stickerTools[themeIndex].find(
          sticker => sticker.id === svgStickerId
        );
        if (targetSVG) {
          svgSource = targetSVG.source;
          break;
        }
      }

      if (svgSource) {
        const svgString = ReactDOMServer.renderToString(svgSource());
        childs[i].innerHTML = svgString;
        const svg = childs[i].querySelector('svg');

        const themeId = childs[i].getAttribute('theme');
        setSVGWidthHeight(svg, themeId);
      }
    }
  }

  return tempWrapper.innerHTML;
};

export const parseDomToJson = dom => {
  const copyDOM = dom.cloneNode(true);
  const allSVGs = copyDOM.getElementsByTagName('svg');

  for (let i = allSVGs.length - 1; i >= 0; i = i - 1) {
    allSVGs[i].parentNode.removeChild(allSVGs[i]);
  }

  const json = parse(copyDOM.outerHTML);
  console.log('🦄', json);
  return json;
};
