import { generateObjectID, stringifyJsonToDom } from '../../lib';
import wallpaper0 from './wallpaper0.json';
import wallpaper1 from './wallpaper1.json';
import wallpaper2 from './wallpaper2.json';

export const allWallpapers = [wallpaper0, wallpaper1, wallpaper2];

export const getObjectSources = originDomTree => {
  const objects = originDomTree[0].children;
  return objects;
};

export const changeJsonToDomTree = objects => {
  const elementNum = objects.filter(item => item.type === 'element').length;

  let originDomTree = stringifyJsonToDom(objects);

  for (let i = 0; i < elementNum; i = i + 1) {
    originDomTree = originDomTree.replace(
      /id='object(.+?)'/i,
      `id="${generateObjectID()}"`
    );
  }

  return originDomTree;
};

export const getThemeObjects = wallpaper => {
  console.log('🦔', wallpaper);
  const objects = getObjectSources(wallpaper);
  const domTree = changeJsonToDomTree(objects);
  return domTree;
};

export const getSpecificBackground = wallpaper => {
  const { attributes } = wallpaper[0];
  const { value: themeId } = attributes.find(item => item.key === 'theme');
  const { value: backgroundId } = attributes.find(
    item => item.key === 'background'
  );
  return { themeId, backgroundId };
};
