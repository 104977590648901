import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import SelectHeader from './SelectHeader';
import DisplayWallpaper from './DisplayWallpaper';
import SelectWallpaper from './SelectWallpaper';

import wallpapers from '../wallpapers';
import { styleSelectImage } from '../../lib';
import { setBackgroundColorToGreen } from '../../lib/other';
import renderWallpaperImage from '../Languages/RenderWallpaperImage';

class SelectPage extends Component {
  state = {
    currentWallpaper: 0,
  };

  componentDidMount() {
    setBackgroundColorToGreen(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentWallpaper !== prevState.currentWallpaper) {
      styleSelectImage(this.state.currentWallpaper);
    }
  }

  onChangeWallpaper = selectId => this.setState({ currentWallpaper: selectId });

  render() {
    const { currentWallpaper } = this.state;
    const { i18n } = this.props;
    const previewImages = renderWallpaperImage(i18n.language);

    return (
      <Fragment>
        <SelectHeader wallpaperId={currentWallpaper} />
        <DisplayWallpaper
          wallpaperId={currentWallpaper}
          previewImages={previewImages}
        />
        <SelectWallpaper
          wallpapers={wallpapers}
          onChangeWallpaper={this.onChangeWallpaper}
          previewImages={previewImages}
        />
      </Fragment>
    );
  }
}

SelectPage.propTypes = {
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(SelectPage);
