import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'reactstrap';
import interact from 'interactjs';

import AppLogo from '../../assets/other/app_logo.png';
import Image404 from '../../assets/other/app_404_placeholder.png';
import ReportLogo from '../../assets/icon/report.png';

// import ChangeLanguage from '../Languages/ChangeLanguage';
import renderMainName from '../Languages/RenderMainName';

import {
  getCurrentBaseUrl,
  pasteUserDefaultStickers,
  getJsonFile,
  sendReportEmail,
  wallpaperToImageResultPage,
  setResultImageMargin,
} from '../../lib/server';
import { openForestHomePage } from '../../lib/other';
import wallpapers, { createUserTheme } from '../wallpapers';

import './index.scss';

class ResultPage extends Component {
  state = {
    backgrounds: null,
    specificBackground: null,
    stickers: null,
    userWallpaperId: '',
    imageUrl: '',
    showImage: false,
    show404: false,
    holdImage: false,
  };

  async componentDidMount() {
    const jsonFile = await getJsonFile();
    if (jsonFile) {
      const {
        source: { backgrounds, specificBackground, stickers },
      } = createUserTheme(jsonFile);
      this.setState({
        backgrounds,
        specificBackground,
        stickers,
        userWallpaperId: wallpapers.length - 1,
      });
    } else {
      this.setState({ show404: true });
    }

    interact('#resultImage').on('hold', () => {
      this.setState({ holdImage: true });
    });

    window.dataLayer.push({ event: 'page_othershare' });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.backgrounds !== prevState.backgrounds) {
      pasteUserDefaultStickers(
        this.state.stickers,
        this.state.specificBackground
      );
      wallpaperToImageResultPage(this.setOutputImageUrl);
      setResultImageMargin();
    }

    if (this.state.holdImage && !prevState.holdImage) {
      window.dataLayer.push({ event: 'btn_othershare_save' });
    }
  }

  setOutputImageUrl = imageUrl => {
    this.setState({ imageUrl });
  };

  backToHomePage = () => {
    const newUrl = getCurrentBaseUrl();
    window.location = newUrl;
  };

  clearParameterId = () => {
    const newurl = getCurrentBaseUrl();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  render() {
    const { t, i18n } = this.props;
    const {
      backgrounds,
      specificBackground,
      userWallpaperId,
      imageUrl,
      showImage,
      show404,
    } = this.state;

    return (
      <div className="resultPage">
        <div className="resultPage-logoAndForest" onClick={openForestHomePage}>
          <img src={AppLogo} alt="AppLogo" className="resultPage-forestLink" />
          <span className="resultPage-forestLink">
            {i18n.language === 'zh-TW' ? 'Forest 專注森林' : 'Forest'}
          </span>
        </div>
        <div className="resultPage-title">{renderMainName(i18n.language)}</div>
        <div id="resultAreaWrapper">
          {backgrounds && (
            <div
              id="resultAreaID"
              className="resultPage-area"
              style={{
                backgroundImage: `url(${
                  backgrounds[parseInt(specificBackground.backgroundId, 10)]
                })`,
                backgroundSize: 'cover',
              }}
            />
          )}
        </div>
        <div id="resultImageWrapper">
          {show404 ? (
            <Fragment>
              <img
                src={Image404}
                alt="Image404"
                className="resultPage-image404"
              />
              <div className="resultPage-404text">{t('errorpage_caption')}</div>
            </Fragment>
          ) : (
            <Fragment>
              <img
                src={imageUrl}
                style={{ visibility: showImage ? 'visible' : 'hidden' }}
                id="resultImage"
                alt="resultImage"
                className="resultPage-image"
                onLoad={() => {
                  this.setState({ showImage: true });
                }}
              />
              {showImage ? (
                <div className="resultPage-text">{t('othershare_caption')}</div>
              ) : (
                <Spinner className="resultPage-spinner" color="light" />
              )}
            </Fragment>
          )}
        </div>

        <Container className="resultPage-bottomRow">
          {!show404 && (
            <div
              id="reportButton"
              className="resultPage-bottomRow-report"
              onClick={sendReportEmail}
            >
              <img src={ReportLogo} alt="ReportLogo" />
              {t('othershare_button_report')}
            </div>
          )}
          <Row className="justify-content-center align-items-center">
            {show404 ? (
              <Col xs={5}>
                <NavLink to="/">
                  <button
                    type="button"
                    className="resultPage-bottomRow-startButton404 resultPage-startButton"
                    onClick={this.backToHomePage}
                  >
                    {t('othershare_button_home')}
                  </button>
                </NavLink>
              </Col>
            ) : (
              <Fragment>
                <Col xs={5}>
                  <NavLink to={`/editor/${userWallpaperId}`}>
                    <button
                      type="button"
                      id="keep-editing"
                      className="resultPage-bottomRow-keepButton"
                      onClick={this.clearParameterId}
                    >
                      {t('othershare_button_edit')}
                    </button>
                  </NavLink>
                </Col>
                <Col xs={5}>
                  <NavLink to="/">
                    <button
                      type="button"
                      className="resultPage-bottomRow-startButton resultPage-startButton"
                      onClick={this.backToHomePage}
                    >
                      {t('othershare_button_home')}
                    </button>
                  </NavLink>
                </Col>
              </Fragment>
            )}
          </Row>
        </Container>
        {/* <div
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
          }}
        >
          <button
            type="button"
            onClick={() => this.setState({ show404: !show404 })}
          >
            Toggle 404
          </button>
        </div>
        <ChangeLanguage /> */}
      </div>
    );
  }
}

ResultPage.propTypes = {
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ResultPage);
