import PropTypes from 'prop-types';
import React from 'react';

import './DisplayWallpaper.scss';

const DisplayWallpaper = ({ wallpaperId, previewImages }) => (
  <div
    className="displayWallpaper"
    style={{
      backgroundImage: `url(${previewImages[wallpaperId]})`,
      backgroundSize: 'cover',
    }}
  />
);

DisplayWallpaper.propTypes = {
  previewImages: PropTypes.array.isRequired,
  wallpaperId: PropTypes.number.isRequired,
};

export default DisplayWallpaper;
