import ReactDOMServer from 'react-dom/server';
import domtoimage from 'dom-to-image';

import { languages } from '../components/Languages/ChangeLanguage';
import { outputImageWidth, outputImageHeight, mobileScale } from '../index';
import { getCorrespondBackgroundComponents } from '../components/backgrounds';

const wallpaperTempBackgroundId = 'wallpaperTempBackground';

export const setBackgroundColorToGreen = bool => {
  if (bool) {
    document.documentElement.style.setProperty('--backgroundColor', '#51a387');
  } else {
    document.documentElement.style.setProperty('--backgroundColor', '#e5e5e5');
  }
};

export const getImageDataUrl = async (wrapper, areaRealWidth) => {
  const styleBasic = {
    transformOrigin: 'top center',
    transform: `scale(${outputImageWidth / areaRealWidth})`,
  };

  let style;
  if (wrapper.getBoundingClientRect().left < 0) {
    style = { left: '0', top: '0', ...styleBasic };
  } else {
    style = styleBasic;
  }

  const area = wrapper.firstChild;
  const themeId = parseInt(area.getAttribute('theme'), 10);
  const backgroundId = parseInt(area.getAttribute('background'), 10);
  const backgroundComponent = getCorrespondBackgroundComponents(themeId)[
    backgroundId
  ];
  const svgBackgroundString = ReactDOMServer.renderToString(
    backgroundComponent()
  );

  const tempBackground = document.createElement('div');
  tempBackground.id = wallpaperTempBackgroundId;
  tempBackground.style.zIndex = '-100';
  tempBackground.innerHTML = svgBackgroundString;
  area.appendChild(tempBackground);

  const dataUrl = await domtoimage.toPng(wrapper, {
    width: outputImageWidth,
    height: outputImageHeight,
    style,
  });

  const wallpaperTempBackground = document.getElementById(
    wallpaperTempBackgroundId
  );
  if (wallpaperTempBackground) wallpaperTempBackground.remove();

  return dataUrl;
};

// 字型需要知道語言
export const setLangOnBody = language => {
  document.body.setAttribute('lang', language);
};

export const setOutputScale = height => {
  const outputScale = outputImageHeight / height / mobileScale;
  document.documentElement.style.setProperty('--outputScale', outputScale);
};

const zhTransform = locale => {
  const zhTW = 'zh-TW';
  const zhCN = 'zh-CN';

  switch (locale) {
    case 'zh-tw':
    case 'zh-Hant':
    case 'zh-Hant-CN':
    case 'zh-Hant-HK':
    case 'zh-Hant-MO':
    case 'zh-Hant-SG':
    case 'zh-Hant-TW':
      return zhTW;
    case 'zh-cn':
    case 'zh-mo':
    case 'zh-MO':
    case 'zh-sg':
    case 'zh-SG':
    case 'zh-hk':
    case 'zh-HK':
    case 'zh-Hans':
    case 'zh-Hans-CN':
    case 'zh-Hans-HK':
    case 'zh-Hans-MO':
    case 'zh-Hans-SG':
    case 'zh-Hans-TW':
      return zhCN;
    default:
      return locale;
  }
};

const sliceLocaleExceptZH = locale => {
  const defaultLocale = 'en';
  const localeHead = locale.slice(0, 2);
  let newLocale;

  if (localeHead !== 'zh') {
    if (languages.includes(localeHead)) {
      newLocale = localeHead;
    } else {
      newLocale = defaultLocale;
    }
  } else {
    newLocale = zhTransform(locale);
  }

  if (locale === 'zh') newLocale = defaultLocale;

  return newLocale;
};

export const detectLangParameterAndSetOnBody = i18n => {
  const urlParams = new URLSearchParams(window.location.search);
  let locale = urlParams.get('locale');

  if (locale && locale !== 'null') {
    locale = sliceLocaleExceptZH(locale);
  } else {
    locale = sliceLocaleExceptZH(i18n.language);
  }

  i18n.changeLanguage(locale);
  setLangOnBody(locale);
};

const getOffsetByLang = (themeId, x, y, scale) => {
  const lang = document.body.getAttribute('lang');
  let newX = x;
  let newY = y;
  let newScale = scale;

  if (themeId === 0) {
    switch (lang) {
      case languages[0]:
        newX = x - 35;
        newY = y + 8;
        break;
      case languages[1]:
        newX = x - 55;
        newY = y + 5;
        break;
      case languages[2]:
        newX = x - 28;
        newY = y + 8;
        break;
      case languages[3]:
        newX = x - 55;
        newY = y - 10;
        break;
      case languages[4]:
        newX = x - 28;
        newY = y + 10;
        break;
      case languages[5]:
        newX = x;
        newY = y + 10;
        break;
      case languages[6]:
        newX = x - 60;
        newY = y - 10;
        break;
      default:
        newX = x;
        newY = y;
        newScale = scale;
    }
  } else if (themeId === 1) {
    switch (lang) {
      case languages[0]:
        newX = x - 48;
        newY = y - 15;
        newScale = scale * 0.63;
        break;
      case languages[1]:
        newX = x - 45;
        newY = y - 5;
        newScale = scale * 0.7;
        break;
      case languages[2]:
        newX = x - 20;
        newY = y;
        newScale = scale * 0.8;
        break;
      case languages[3]:
        newX = x - 50;
        newY = y - 20;
        newScale = scale * 0.8;
        break;
      case languages[4]:
        newX = x - 38;
        newY = y - 8;
        newScale = scale * 0.75;
        break;
      case languages[5]:
        newX = x + 8;
        newY = y - 15;
        break;
      case languages[6]:
        newX = x - 42;
        newY = y - 20;
        newScale = scale * 0.75;
        break;
      default:
        newX = x;
        newY = y;
        newScale = scale;
    }
  } else if (themeId === 2) {
    switch (lang) {
      case languages[0]:
        newX = x - 25;
        newY = y - 5;
        break;
      case languages[1]:
        newX = x - 5;
        newY = y + 5;
        break;
      case languages[2]:
        newX = x - 28;
        newY = y - 10;
        break;
      case languages[3]:
        newX = x - 30;
        newY = y - 10;
        newScale = scale * 0.9;
        break;
      case languages[4]:
        newX = x + 2;
        newY = y + 5;
        break;
      case languages[5]:
        newX = x + 15;
        newY = y;
        newScale = scale * 0.9;
        break;
      case languages[6]:
        newX = x - 25;
        newY = y - 10;
        break;
      default:
        newX = x;
        newY = y;
        newScale = scale;
    }
  }

  return { newX, newY, newScale };
};

const addTransformOffset = (themeId, target) => {
  const x = parseFloat(target.getAttribute('data-x')) || 0;
  const y = parseFloat(target.getAttribute('data-y')) || 0;
  const scale = parseFloat(target.getAttribute('scale')) || 1;

  const { newX, newY, newScale } = getOffsetByLang(
    themeId,
    parseInt(x, 10),
    parseInt(y, 10),
    parseFloat(scale)
  );

  const command = `translate(${newX}px, ${newY}px) rotate(0rad) scale(${newScale})`;
  target.style.webkitTransform = command;
  target.style.transform = command;

  target.setAttribute('data-x', newX);
  target.setAttribute('data-y', newY);
  target.setAttribute('scale', newScale);
};

export const changeDefaultTextAndRemoveClass = (themeId, t) => {
  let targetText;
  switch (themeId) {
    case 0: {
      targetText = t('main_default_text_theme1');
      break;
    }
    case 1: {
      targetText = t('main_default_text_theme2');
      break;
    }
    case 2: {
      targetText = t('main_default_text_theme3');
      break;
    }
    default:
      break;
  }

  const elements = document.getElementsByClassName('defaultText');
  if (elements.length !== 0) {
    const text = elements[0].getElementsByClassName(
      'editable-textBox-value'
    )[0];

    targetText = targetText.replace('\n', '<br>');
    text.innerHTML = targetText;
    addTransformOffset(themeId, elements[0]);
    elements[0].classList.remove('defaultText');
  }
};

// 設計師們的實際尺寸未統一，所以貼上工作區域的初始貼圖大小會有差異。要依照比例去做調整
const getSvgScaleDependOnTheme = themeId => {
  let svgScale;

  switch (themeId) {
    case 0:
      svgScale = 1.1;
      break;
    case 1:
      svgScale = 1.1;
      break;
    case 2:
      svgScale = 8;
      break;
    default:
      svgScale = 0;
      break;
  }

  return svgScale;
};

export const setSVGWidthHeight = (svg, themeId) => {
  const viewbox = svg.getAttribute('viewBox').split(' ');
  const svgScale = getSvgScaleDependOnTheme(parseInt(themeId, 10));

  const width = viewbox[2];
  svg.setAttribute('width', parseInt(width, 10) / svgScale);
  const height = viewbox[3];
  svg.setAttribute('height', parseInt(height, 10) / svgScale);
};

export const openForestHomePage = () =>
  window.open('https://www.forestapp.cc/');
