import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import interact from 'interactjs';
import { Spinner } from 'reactstrap';

import {
  changeModeToEdit,
  toSelectObject,
  changeTool,
  toSetPrevObjectsStateHTML,
  toSetPrevBackgroundState,
  toSelectBackground,
} from '../../states/actions';
// import ChangeLanguage from '../Languages/ChangeLanguage';
import EditorHeader from './EditorHeader';
import WorkingArea from './WorkingArea';
import ToolBox from './ToolBox';
import PreviewImage from './PreviewImage';
import MoreStickersModal from './MoreStickersModal';
import TextBoxModal from './TextBoxModal';
import ClearContentAlert from './ClearContentAlert';
import SavePage from '../SavePage';

import wallpapers from '../wallpapers';

import {
  dragMove,
  resizeMove,
  clearAllObjectSelectStyle,
  pasteDefaultStickers,
  dependObjectToChangeTool,
  workingAreaID,
  startDragAngle,
  endDragAngle,
  startDragDistanceFromCenter,
  makeObjectTransparent,
  calculateMargin,
  styleSelectObject,
  enableObjectTool,
  styleSelectImage,
  changeStickerThemeButtonStyle,
  rememberCurrentBackground,
  getDefaultBackground,
  getDefaultTheme,
  addCanDragClass,
  removeCanDragClass,
  previewAreaToImage,
  // showBlob,
} from '../../lib';
import { signUpWallpaper, uploadFiles } from '../../lib/server';
import {
  setBackgroundColorToGreen,
  changeDefaultTextAndRemoveClass,
} from '../../lib/other';

import './index.scss';

class EditorPage extends Component {
  state = {
    stickerModalIsOpen: false,
    textModalIsOpen: false,
    clearAlertIsOpen: false,
    whichAlert: 0,
    isOutputPage: false,
    stickersThemeId: 0,
    selectWhichThemeToShowId: 0,
    selectTextBoxId: '',
    imageUrl: '',
    shareImageId: '',
    startLoading: false,
    previewImageUrl: '',
  };

  componentDidMount() {
    const {
      match: { params },
      changeModeToEdit: changeModeToEditFromProps,
      toSelectObject: toSelectObjectFromProps,
      changeTool: changeToolFromProps,
      toSelectBackground: toSelectBackgroundFromProps,
      selectBackgroundId,
      t,
    } = this.props;
    window.history.replaceState(null, null, window.location.pathname);

    const themeId = getDefaultTheme(params.wallpaperId);
    this.setState({ stickersThemeId: parseInt(themeId, 10) });

    const background = getDefaultBackground(params.wallpaperId);
    toSelectBackgroundFromProps(parseInt(background, 10));
    pasteDefaultStickers(params.wallpaperId);
    calculateMargin();
    rememberCurrentBackground({
      theme: parseInt(themeId, 10),
      background: selectBackgroundId,
    });
    changeDefaultTextAndRemoveClass(parseInt(themeId, 10), t);

    const startResizeDrag = event => {
      changeModeToEditFromProps(true);
      toSelectObjectFromProps(event.currentTarget.id);
      dependObjectToChangeTool(event.currentTarget.id, changeToolFromProps);
    };

    interact('.resize-drag')
      .on('tap', event => {
        startResizeDrag(event);
        event.stopPropagation();
      })
      .on('down', event => {
        const { currentTarget } = event;
        if (currentTarget.classList.contains('canDrag')) {
          startResizeDrag(event);
        }
        event.stopPropagation();
      })
      .draggable({
        allowFrom: '.canDrag',
        restrict: {
          restriction: 'parent',
          elementRect: { top: 0.5, left: 0.5, bottom: 0.5, right: 0.5 },
        },
        onmove: event => {
          dragMove(event);
        },
      })
      .resizable({
        edges: {
          right: '.resize-corner',
          top: '.resize-corner',
        },
        invert: 'none',
        onstart: event => {
          startDragAngle(event);
          startDragDistanceFromCenter(event);
        },
        onend: event => {
          endDragAngle(event);
        },
      })
      .on('resizemove', resizeMove);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectObjectId !== prevProps.selectObjectId) {
      styleSelectObject(this.props.selectObjectId);
    }

    if (this.props.isEditingMode && !prevProps.isEditingMode) {
      const objectsStateHTML = document.getElementById(workingAreaID).innerHTML;
      this.props.toSetPrevObjectsStateHTML(objectsStateHTML);
      this.props.toSetPrevBackgroundState(this.props.selectBackgroundId);
      addCanDragClass();
    }

    if (!this.props.isEditingMode && prevProps.isEditingMode) {
      this.props.changeTool(3);
      removeCanDragClass();
    }

    if (this.props.currentTool === 'BACKGROUND') {
      makeObjectTransparent(true);
    } else {
      makeObjectTransparent(false);
    }

    if (this.props.selectObjectId) {
      enableObjectTool(true);
    } else {
      enableObjectTool(false);
    }

    if (this.props.currentTool !== prevProps.currentTool) {
      calculateMargin();
    }

    if (this.state.isOutputPage === true) {
      setBackgroundColorToGreen(true);
    } else {
      setBackgroundColorToGreen(false);
    }

    if (this.props.selectBackgroundId !== prevProps.selectBackgroundId) {
      styleSelectImage(this.props.selectBackgroundId);
    }

    if (this.props.selectBackgroundId !== prevProps.selectBackgroundId) {
      rememberCurrentBackground({
        background: this.props.selectBackgroundId,
      });
    }
  }

  renderOutputPage = isOutputPage => {
    this.setState({ isOutputPage });

    if (isOutputPage === true) {
      window.dataLayer.push({ event: 'page_savewp' });
    }
  };

  showLoadingSpinner = () => {
    this.setState({ startLoading: true });
  };

  finishOutputImage = id => {
    this.setState({ shareImageId: id, startLoading: false });
    this.renderOutputPage(true);
  };

  uploadWallpaperInfomation = async ({ json }) => {
    console.log('Sign Up Wallpaper ...');
    const { id, token } = await signUpWallpaper();

    try {
      console.log('Preview Area To Blob ...');
      const blob = await previewAreaToImage();
      // 看海報有無輸出成功
      // showBlob(blob);

      const formData = new FormData();
      // formData.append('poster', 'testError');
      formData.append('poster', blob);
      formData.append('json', JSON.stringify(json));

      console.log('Upload Files ...');
      const response = await uploadFiles({ id, token, formData });
      console.log({ response });
    } catch (error) {
      // FIXME: 不好的做法
      this.finishOutputImage(id);
    }

    this.finishOutputImage(id);

    // if (response) {
    //   // const correctStatus = response.status === 200;
    //   const correctStatus = response.status === 200 || response.status === 422;

    //   if (correctStatus) {
    //     this.setState({ shareImageId: id });
    //     this.renderOutputPage(true);
    //   } else {
    //     alert(`Try Again ! Something Wrong ( ${response.status} )`);
    //   }
    // } else {
    //   alert('Try Again ! Something Wrong');
    // }
  };

  toggleTextModal = selectObjectId => {
    this.setState({
      selectTextBoxId: selectObjectId,
      textModalIsOpen: !this.state.textModalIsOpen,
    });
  };

  toggleStickerModal = () => {
    const { stickersThemeId } = this.state;

    this.setState({
      stickerModalIsOpen: !this.state.stickerModalIsOpen,
      selectWhichThemeToShowId: stickersThemeId,
    });
  };

  toggleClearAlert = ({ whichAlert }) => {
    const { toSelectObject: toSelectObjectFromProps } = this.props;
    clearAllObjectSelectStyle(toSelectObjectFromProps);

    this.setState({
      clearAlertIsOpen: !this.state.clearAlertIsOpen,
    });

    if (whichAlert !== 0) this.setState({ whichAlert });
  };

  selectWhichThemeToShow = id => {
    this.setState({ selectWhichThemeToShowId: id });
    changeStickerThemeButtonStyle(id);
  };

  setOutputImageUrl = imageUrl => {
    this.setState({ imageUrl });
  };

  setPreviewImageUrl = url => {
    this.setState({ previewImageUrl: url });
  };

  render() {
    const {
      match: { params },
      selectBackgroundId,
      isEditingMode,
      currentTool,
      t,
    } = this.props;

    const {
      stickerModalIsOpen,
      textModalIsOpen,
      clearAlertIsOpen,
      whichAlert,
      isOutputPage,
      stickersThemeId,
      selectWhichThemeToShowId,
      selectTextBoxId,
      imageUrl,
      shareImageId,
      startLoading,
      previewImageUrl,
    } = this.state;

    const wallpaperId = parseInt(params.wallpaperId, 10);

    let objectsStateHTML;
    const workingArea = document.getElementById(workingAreaID);
    if (workingArea) {
      objectsStateHTML = workingArea.innerHTML;
    }

    return (
      <div>
        <PreviewImage
          previewImageUrl={previewImageUrl}
          uploadWallpaperInfomation={this.uploadWallpaperInfomation}
        />
        <div style={{ display: isOutputPage ? 'block' : 'none' }}>
          <SavePage
            imageUrl={imageUrl}
            shareImageId={shareImageId}
            renderOutputPage={this.renderOutputPage}
            setPreviewImageUrl={this.setPreviewImageUrl}
          />
        </div>
        <div style={{ display: isOutputPage ? 'none' : 'block' }}>
          {!isEditingMode && (
            <div
              id="clearAllObjectsButton"
              style={{ opacity: objectsStateHTML ? '1.0' : '0.4' }}
              onClick={() => {
                if (objectsStateHTML) this.toggleClearAlert({ whichAlert: 2 });
              }}
            >
              <span>{t('main_button_claerall')}</span>
            </div>
          )}
          <EditorHeader
            toggleClearAlert={() => this.toggleClearAlert({ whichAlert: 1 })}
            toggleCancelAlert={() => this.toggleClearAlert({ whichAlert: 3 })}
            toggleTextModal={this.toggleTextModal}
            setOutputImageUrl={this.setOutputImageUrl}
            showLoadingSpinner={this.showLoadingSpinner}
            setPreviewImageUrl={this.setPreviewImageUrl}
          />
          {startLoading && (
            <Spinner className="editorPage-spinner" color="light" />
          )}
          <WorkingArea
            currentTool={currentTool}
            background={
              wallpapers[wallpaperId].source.backgrounds[selectBackgroundId]
            }
          />
          <ToolBox
            toggleStickerModal={this.toggleStickerModal}
            toggleTextModal={this.toggleTextModal}
            stickersThemeId={stickersThemeId}
            wallpaperId={wallpaperId}
          />
          <TextBoxModal
            selectTextBoxId={selectTextBoxId}
            isOpen={textModalIsOpen}
            toggle={this.toggleTextModal}
          />
          <MoreStickersModal
            isOpen={stickerModalIsOpen}
            toggle={this.toggleStickerModal}
            selectWhichThemeToShowId={selectWhichThemeToShowId}
            setStickersTheme={id => this.setState({ stickersThemeId: id })}
            selectWhichThemeToShow={this.selectWhichThemeToShow}
          />
          <ClearContentAlert
            isOpen={clearAlertIsOpen}
            toggle={() => this.toggleClearAlert({ whichAlert: 0 })}
            whichAlert={whichAlert}
          />
          {/* <ChangeLanguage /> */}
        </div>
      </div>
    );
  }
}

EditorPage.propTypes = {
  changeModeToEdit: PropTypes.func.isRequired,
  changeTool: PropTypes.func.isRequired,
  currentTool: PropTypes.string.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      wallpaperId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  selectBackgroundId: PropTypes.number.isRequired,
  selectObjectId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  toSelectBackground: PropTypes.func.isRequired,
  toSelectObject: PropTypes.func.isRequired,
  toSetPrevBackgroundState: PropTypes.func.isRequired,
  toSetPrevObjectsStateHTML: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(
    state => ({
      ...state.object,
      ...state.tool,
      ...state.background,
      ...state.mode,
    }),
    {
      changeModeToEdit,
      toSelectObject,
      changeTool,
      toSetPrevObjectsStateHTML,
      toSetPrevBackgroundState,
      toSelectBackground,
    }
  )(EditorPage)
);
