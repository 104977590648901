import arraymove from 'array-move';
import html2canvas from 'html2canvas';

import themeStickerIcon1 from '../assets/icon/theme/theme_sticker_icon_1.png';
import themeStickerIcon1Active from '../assets/icon/theme/theme_sticker_icon_1_picked.png';
import themeStickerIcon2 from '../assets/icon/theme/theme_sticker_icon_2.png';
import themeStickerIcon2Active from '../assets/icon/theme/theme_sticker_icon_2_picked.png';
import themeStickerIcon3 from '../assets/icon/theme/theme_sticker_icon_3.png';
import themeStickerIcon3Active from '../assets/icon/theme/theme_sticker_icon_3_picked.png';

import wallpapers from '../components/wallpapers';
import { setInitialPosition, setCornerScale } from './tranform';
import { getImageDataUrl, setOutputScale, setSVGWidthHeight } from './other';

const originThemeSticker = [
  themeStickerIcon1,
  themeStickerIcon2,
  themeStickerIcon3,
];

const activeThemeSticker = [
  themeStickerIcon1Active,
  themeStickerIcon2Active,
  themeStickerIcon3Active,
];

export * from './tranform';
export * from './parse';

export const workingAreaID = 'workingAreaID';
export const previewImageID = 'previewImageID';

let currentID = -1;

const imageSelectShadowValue = 'inset 0 0 0 3px #4f4f4f';

export const generateObjectID = () => {
  currentID = currentID + 1;
  return `object${currentID}`;
};

export const dependObjectToChangeTool = (id, changeTool) => {
  const element = document.getElementById(id);
  if (element.classList.contains('editable-textBox')) {
    changeTool(1);
  } else {
    changeTool(2);
  }
};

export const initialSelectBoxShadow = (index, selectBackgroundId) => {
  if (index === selectBackgroundId) return imageSelectShadowValue;
  return 'none';
};

export const styleSelectImage = current => {
  const elements = document.getElementsByClassName('imageToBeSelected');
  for (let i = 0; i < elements.length; i = i + 1) {
    if (elements[i].id === current.toString()) {
      elements[i].style.boxShadow = imageSelectShadowValue;
    } else {
      elements[i].style.boxShadow = 'none';
    }
  }
};

export const enableObjectTool = enable => {
  const objectTools = document.getElementsByClassName('objectTool');
  let opacity;
  if (enable) opacity = 1;
  else opacity = 0.4;

  for (let i = 0; i < objectTools.length; i = i + 1) {
    objectTools[i].style.opacity = opacity;
  }
};

export const pasteDefaultStickers = wallpaperId => {
  const workingArea = document.getElementById(workingAreaID);
  const stickersSourceHTML =
    wallpapers[parseInt(wallpaperId, 10)].source.stickers;
  const fragment = document
    .createRange()
    .createContextualFragment(stickersSourceHTML);
  workingArea.appendChild(fragment);
};

export const getDefaultTheme = wallpaperId => {
  const { themeId } = wallpapers[
    parseInt(wallpaperId, 10)
  ].source.specificBackground;
  return themeId;
};

export const getDefaultBackground = wallpaperId => {
  const { backgroundId } = wallpapers[
    parseInt(wallpaperId, 10)
  ].source.specificBackground;
  return backgroundId;
};

export const rememberCurrentBackground = ({ theme, background }) => {
  const workingArea = document.getElementById(workingAreaID);
  if (theme !== undefined) workingArea.setAttribute('theme', theme);
  workingArea.setAttribute('background', background);
};

export const makeObjectTransparent = bool => {
  const workingArea = document.getElementById(workingAreaID);
  const elements = document.querySelectorAll('.resize-drag');
  const wrapper = document.createElement('div');
  const wrapperClass = 'tempOpacityWrapperClass';
  wrapper.classList.add(wrapperClass);

  if (bool) {
    for (let i = 0; i < elements.length; i = i + 1) {
      wrapper.appendChild(elements[i]);
    }
    wrapper.style.opacity = 0.5;
    workingArea.appendChild(wrapper);
  } else {
    for (let i = 0; i < elements.length; i = i + 1) {
      workingArea.appendChild(elements[i]);
    }
    const tempOpacityWrappers = document.getElementsByClassName(wrapperClass);
    while (tempOpacityWrappers.length > 0) {
      tempOpacityWrappers[0].parentNode.removeChild(tempOpacityWrappers[0]);
    }
  }
};

export const fillTextBoxModalValue = selectObjectId => {
  const object = document.getElementById(selectObjectId);
  let value = object.getElementsByClassName('editable-textBox-value')[0]
    .innerHTML;
  value = value.replace(/<br ?\/?>/g, '\n');
  document.getElementById('userInput').value = value;
};

const setPositionAndAppendToWorkingArea = element => {
  setInitialPosition(element);
  document.getElementById(workingAreaID).appendChild(element);
};

export const createTextBox = (input, toSelectObject) => {
  const element = document.createElement('div');
  const generateID = generateObjectID();
  element.setAttribute('id', generateID);

  const textbox = document.createElement('div');
  textbox.setAttribute('class', 'editable-textBox-value');
  textbox.style.textAlign = 'center';

  input = input.replace(/\r?\n/g, '<br />');
  textbox.innerHTML = input;

  element.appendChild(textbox);
  element.setAttribute('class', 'resize-drag canDrag editable-textBox');
  element.style.color = 'rgb(0, 0, 0)';

  setPositionAndAppendToWorkingArea(element);
  toSelectObject(generateID);
};

export const editTextBox = (input, selectObjectId) => {
  const object = document.getElementById(selectObjectId);
  input = input.replace(/\r?\n/g, '<br />');
  object.getElementsByClassName('editable-textBox-value')[0].innerHTML = input;
};

const getObjectsToArrayFromWorkingArea = () => {
  const workingArea = document.getElementById(workingAreaID);
  const items = [...workingArea.getElementsByClassName('resize-drag')];
  return items;
};

const getSelectObjectIndex = selectObjectId => {
  const items = getObjectsToArrayFromWorkingArea();
  let selectObjectIndex;

  items.forEach((element, idx) => {
    if (element.id === selectObjectId) selectObjectIndex = idx;
  });

  return selectObjectIndex;
};

export const deleteObject = (selectObjectId, toSelectObject) => {
  toSelectObject('');
  const object = document.getElementById(selectObjectId);
  if (object) object.remove();
};

export const deleteAllObjects = () => {
  const workingArea = document.getElementById(workingAreaID);
  while (workingArea.childNodes.length) {
    workingArea.removeChild(workingArea.lastChild);
  }
};

const appendToWorkingArea = items => {
  const workingArea = document.getElementById(workingAreaID);
  const elements = document.createDocumentFragment();

  items.forEach(element => {
    elements.appendChild(element.cloneNode(true));
  });

  deleteAllObjects();
  workingArea.appendChild(elements);
};

export const moveLayerUp = selectObjectId => {
  let items = getObjectsToArrayFromWorkingArea();
  const selectObjectIndex = getSelectObjectIndex(selectObjectId);

  if (selectObjectIndex + 1 < items.length) {
    items = arraymove(items, selectObjectIndex, selectObjectIndex + 1);
    appendToWorkingArea(items);
  }
};

export const moveLayerDown = selectObjectId => {
  let items = getObjectsToArrayFromWorkingArea();
  const selectObjectIndex = getSelectObjectIndex(selectObjectId);

  if (selectObjectIndex > 0) {
    items = arraymove(items, selectObjectIndex, selectObjectIndex - 1);
    appendToWorkingArea(items);
  }
};

export const clearAllObjectSelectStyle = toSelectObject => {
  toSelectObject('');
  const elements = document.getElementsByClassName('resize-drag');
  for (let i = 0; i < elements.length; i = i + 1) {
    if (elements[i]) {
      const resizeCorner = elements[i].querySelector('.resize-corner');
      if (resizeCorner) resizeCorner.remove();
    }
  }
};

export const changeSelectTextColor = ({ color, targetId }) => {
  const target = document.getElementById(targetId);
  if (target) target.style.color = color;
};

export const copyStickerAndAppend = (stickerId, toSelectObject) => {
  const element = document.getElementById(stickerId);
  const clone = element.cloneNode(true);
  clone.setAttribute('class', 'resize-drag canDrag');
  const generateID = generateObjectID();
  clone.setAttribute('id', generateID);
  clone.setAttribute('svgStickerId', stickerId);

  const svg = clone.querySelector('svg');
  if (svg) {
    const themeId = clone.getAttribute('theme');
    setSVGWidthHeight(svg, themeId);
  }

  setPositionAndAppendToWorkingArea(clone);
  toSelectObject(generateID);
};

export const wallpaperToImage = async setOutputImageUrl => {
  const workingAreaWrapper = document.getElementById('workingAreaWrapper');
  const workingArea = document.getElementById(workingAreaID);
  setOutputScale(workingAreaWrapper.offsetHeight);
  workingArea.style.transition = 'all 0s';
  const workingAreaRealWidth = workingArea.getBoundingClientRect().width;

  try {
    const dataUrl = await getImageDataUrl(
      workingAreaWrapper,
      workingAreaRealWidth
    );

    if (setOutputImageUrl) setOutputImageUrl(dataUrl);
    return dataUrl;
  } catch (error) {
    console.error('oops, something went wrong!', error);
  }
};

const getCanvasBlob = canvas =>
  new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob);
    });
  });

export const previewAreaToImage = async () => {
  const previewImageAreaWrapper = document.getElementById('previewImageAreaID');

  const previewImageWidth = 1200;
  const previewImageHeight = 630;

  try {
    const canvas = await html2canvas(previewImageAreaWrapper, {
      width: previewImageWidth,
      height: previewImageHeight,
      scale: 1
    });
    console.log('Canvas: Width:' + canvas.width + ' Height: ' + canvas.height);
    const blob = await getCanvasBlob(canvas);
    console.log('Blob size: ' + blob.size);
    return blob;
  } catch (error) {
    console.error('oops, something went wrong!', error);
  }
};

export const calculateMargin = () => {
  const workingArea = document.getElementById(workingAreaID);
  const toolBox = document.getElementById('toolBoxID');

  document.documentElement.style.setProperty(
    '--workingAreaHeight',
    `${workingArea.clientHeight}px`
  );
  document.documentElement.style.setProperty(
    '--toolBoxHeight',
    `${toolBox.clientHeight}px`
  );
};

export const isExistAnyTextBox = () => {
  const textBoxs = document.getElementsByClassName('editable-textBox');
  if (textBoxs.length) return true;
  return false;
};

export const styleSelectObject = id => {
  const elements = document.getElementsByClassName('resize-drag');
  for (let i = 0; i < elements.length; i = i + 1) {
    if (elements[i].id === id) {
      elements[i].style.backgroundColor = 'rgba(0, 0, 0, 0.1)';

      const child = document.createElement('div');
      child.classList.add('resize-corner');
      const scale = parseFloat(elements[i].getAttribute('scale')) || 1;
      setCornerScale(child, scale);

      elements[i].prepend(child);
    } else {
      elements[i].style.backgroundColor = 'rgba(0, 0, 0, 0)';
      elements[i].style.outline = '1px solid transparent';
      const resizeCorner = elements[i].querySelector('.resize-corner');
      if (resizeCorner) resizeCorner.remove();
    }
  }
};

export const changeStickerThemeButtonStyle = id => {
  const elements = document.getElementsByClassName('stickerCategory');

  for (let index = 0; index < elements.length; index = index + 1) {
    const image = elements[index].querySelector('img');

    if (index === id) {
      elements[index].classList.add('active');
      image.src = activeThemeSticker[index];
    } else {
      elements[index].classList.remove('active');
      image.src = originThemeSticker[index];
    }
  }
};

export const addCanDragClass = () => {
  const elements = document.getElementsByClassName('resize-drag');
  for (let i = 0; i < elements.length; i = i + 1) {
    elements[i].classList.add('canDrag');
  }
};

export const removeCanDragClass = () => {
  const elements = document.getElementsByClassName('resize-drag');
  for (let i = 0; i < elements.length; i = i + 1) {
    elements[i].classList.remove('canDrag');
  }
};

export const showBlob = blob => {
  const img = document.createElement('img');
  const objectUrl = window.URL.createObjectURL(blob);
  img.onload = () => {
    window.URL.revokeObjectURL(img.src);
  };
  img.src = objectUrl;
  document.body.appendChild(img);
};
