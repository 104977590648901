import axios from 'axios';

import { savePageScale, resultPageScale, outputImageWidth } from '../index';
import { getImageDataUrl, setOutputScale } from './other';
import testWallpaper from '../components/defaultThemes/testWallpaper';

const chinaUrlHost = 'forest-wp.upwardsware.com';
const generalUrlHost = 'forest-wp.seekrtech.com';

const urlHost = window.location.host;
console.log(`urlHost: ${urlHost}`);

if (urlHost === chinaUrlHost) {
  axios.defaults.baseURL = `https://${chinaUrlHost}/api`;
} else if (urlHost === generalUrlHost) {
  axios.defaults.baseURL = `https://${generalUrlHost}/api`;
} else {
  axios.defaults.baseURL = `https://${generalUrlHost}/api`;
}

console.log('API BaseURL:', axios.defaults.baseURL);

const resultAreaID = 'resultAreaID';

export const isChinaVersionUrl = () => {
  if (urlHost === chinaUrlHost) return true;
  return false;
};

export const signUpWallpaper = async () => {
  try {
    const response = await axios.post('/wallpapers');
    // {id, url, token}
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const uploadFiles = async ({ id, token, formData }) => {
  try {
    const response = await axios({
      method: 'put',
      url: `/wallpapers/${id}`,
      headers: { 'Content-Type': 'multipart/form-data', 'X-Auth-Token': token },
      data: formData,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getMetaTagJsonFile = () => {
  const metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i = i + 1) {
    if (metas[i].getAttribute('property') === 'forest:wallpaper:json') {
      const content = metas[i].getAttribute('content');
      return content;
    }
  }
  return null;
};

export const getCurrentBaseUrl = () => {
  const { protocol, host, pathname } = window.location;
  const baseUrl = `${protocol}//${host}${pathname}`;
  return baseUrl;
};

export const getJsonFromRemoteUrl = async url => {
  try {
    const jsonFile = await axios.get(url);
    console.log(jsonFile);
    return jsonFile;
  } catch (error) {
    console.error(error);
  }
};

export const pasteUserDefaultStickers = (
  stickersSourceHTML,
  specificBackground
) => {
  const resultArea = document.getElementById(resultAreaID);
  const { themeId, backgroundId } = specificBackground;
  resultArea.setAttribute('theme', themeId);
  resultArea.setAttribute('background', backgroundId);

  const fragment = document
    .createRange()
    .createContextualFragment(stickersSourceHTML);
  resultArea.appendChild(fragment);
};

// 自己 Local 測試`，用 wallpaper0 當範例；Production 再真的拿 Header 的 Json
export const getJsonFile = async () => {
  const meteTagJsonUrl = getMetaTagJsonFile();
  if (process.env.NODE_ENV === 'production' && meteTagJsonUrl) {
    try {
      const { data } = await getJsonFromRemoteUrl(meteTagJsonUrl);
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  const data = testWallpaper;
  return data;

  // TEST 404 Page
  // try {
  //   const { data } = await getJsonFromRemoteUrl(
  //     'https://seekrtech-forest-wallpaper.s3.amazonaws.com/66666.json'
  //   );
  //   return data;
  // } catch (error) {
  //   console.error(error);
  //   return null;
  // }
};

export const sendReportEmail = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const wallpaperId = urlParams.get('id') || '';
  const recipient = `forest.wp.h5+${wallpaperId}@seekrtech.com`;
  window.open(`mailto:${recipient}`);
};

export const setOutputImageMargin = () => {
  const outputScale = getComputedStyle(document.body).getPropertyValue(
    '--outputScale'
  );
  const width = outputImageWidth * (savePageScale / outputScale);
  const margin = (window.innerWidth - width) / 2;
  document.documentElement.style.setProperty('--outputImageMargin', margin);
};

export const setResultImageMargin = () => {
  const outputScale = getComputedStyle(document.body).getPropertyValue(
    '--outputScale'
  );

  const width = outputImageWidth * (resultPageScale / outputScale);
  const margin = (window.innerWidth - width) / 2;
  document.documentElement.style.setProperty('--resultImageMargin', margin);
};

export const wallpaperToImageResultPage = async setOutputImageUrl => {
  const resultAreaWrapper = document.getElementById('resultAreaWrapper');
  const resultArea = document.getElementById(resultAreaID);
  setOutputScale(resultAreaWrapper.offsetHeight);
  const resultAreaRealWidth = resultArea.getBoundingClientRect().width;

  try {
    const dataUrl = await getImageDataUrl(
      resultAreaWrapper,
      resultAreaRealWidth
    );
    if (setOutputImageUrl) setOutputImageUrl(dataUrl);
    return dataUrl;
  } catch (error) {
    console.error('oops, something went wrong!', error);
  }
};
