import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import { wallpaperToImage } from '../../lib';

import commonBackIcon from '../../assets/icon/common_back.png';
import RenderEditingHeader from './RenderEditingHeader';

import './EditorHeader.scss';

const EditorHeader = ({
  isEditingMode,
  toggleClearAlert,
  toggleCancelAlert,
  setOutputImageUrl,
  setPreviewImageUrl,
  toggleTextModal,
  showLoadingSpinner,
  t,
}) => (
  <Container className="editorHeader">
    <Row className="justify-content-between align-items-center editorHeader-row">
      {!isEditingMode ? (
        <Fragment>
          <Col xs={4} className="editorHeader-row-backAction">
            <div
              onClick={toggleClearAlert}
              className="editorHeader-row-backAction-div"
            >
              <img src={commonBackIcon} alt="commonBackIcon" />
              {t('main_button_theme')}
            </div>
          </Col>
          <Col xs={4} className="editorHeader-row-outputAction">
            <div
              className="editorHeader-row-outputAction-div"
              onClick={async () => {
                showLoadingSpinner();
                console.log('PNG ...');
                const dataUrl = await wallpaperToImage(setOutputImageUrl);
                console.log('Set Preview Image Url ...');
                setPreviewImageUrl(dataUrl);
              }}
            >
              {t('main_button_import')}
            </div>
          </Col>
        </Fragment>
      ) : (
        <RenderEditingHeader
          toggleTextModal={toggleTextModal}
          toggleCancelAlert={toggleCancelAlert}
        />
      )}
    </Row>
  </Container>
);

EditorHeader.propTypes = {
  isEditingMode: PropTypes.bool.isRequired,
  setOutputImageUrl: PropTypes.func.isRequired,
  setPreviewImageUrl: PropTypes.func.isRequired,
  showLoadingSpinner: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleCancelAlert: PropTypes.func.isRequired,
  toggleClearAlert: PropTypes.func.isRequired,
  toggleTextModal: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(
    state => ({
      ...state.mode,
    }),
    {}
  )(EditorHeader)
);
