import React from 'react';

import { languages } from './ChangeLanguage';

import YellowMainNameDE from '../../assets/other/yellow_main_name_locale/de.png';
import YellowMainNameEN from '../../assets/other/yellow_main_name_locale/en.png';
import YellowMainNameES from '../../assets/other/yellow_main_name_locale/es.png';
import YellowMainNameFR from '../../assets/other/yellow_main_name_locale/fr.png';
import YellowMainNameJA from '../../assets/other/yellow_main_name_locale/ja.png';
import YellowMainNameKO from '../../assets/other/yellow_main_name_locale/ko.png';
import YellowMainNameRU from '../../assets/other/yellow_main_name_locale/ru.png';
import YellowMainNameCN from '../../assets/other/yellow_main_name_locale/zh-CN.png';
import YellowMainNameTW from '../../assets/other/yellow_main_name_locale/zh-TW.png';

const renderYellowMainName = lang => {
  switch (lang) {
    case languages[0]: {
      return <img src={YellowMainNameDE} alt="YellowMainNameDE" />;
    }
    case languages[1]: {
      return <img src={YellowMainNameEN} alt="YellowMainNameEN" />;
    }
    case languages[2]: {
      return <img src={YellowMainNameES} alt="YellowMainNameES" />;
    }
    case languages[3]: {
      return <img src={YellowMainNameFR} alt="YellowMainNameFR" />;
    }
    case languages[4]: {
      return <img src={YellowMainNameJA} alt="YellowMainNameJA" />;
    }
    case languages[5]: {
      return <img src={YellowMainNameKO} alt="YellowMainNameKO" />;
    }
    case languages[6]: {
      return <img src={YellowMainNameRU} alt="YellowMainNameRU" />;
    }
    case languages[7]: {
      return <img src={YellowMainNameCN} alt="YellowMainNameCN" />;
    }
    case languages[8]: {
      return <img src={YellowMainNameTW} alt="YellowMainNameTW" />;
    }
    default:
      return (
        <div>
          Language:&nbsp;
          {lang}
        </div>
      );
  }
};

export default renderYellowMainName;
