import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Carousel, CarouselItem } from "reactstrap";

import AppLogo from "../../assets/other/app_logo.png";
import QRCodeCN from "../../assets/other/QRcodeCN.svg";
import QRCodeGeneral from "../../assets/other/QRcodeGeneral.svg";
import CompanyLogo from "../../assets/other/seekrtech_logo.png";
import Phone from "../../assets/other/web_phone.png";
import renderWallpaperImage from "../Languages/RenderWallpaperImage";
import renderYellowMainName from "../Languages/RenderYellowMainName";
// import ChangeLanguage from '../Languages/ChangeLanguage';

import "./index.scss";
import { detectLangParameterAndSetOnBody, openForestHomePage } from "../../lib/other";
import { isChinaVersionUrl } from "../../lib/server";

const previewImagesNumber = renderWallpaperImage().length;

class DesktopPage extends Component {
    state = { activeIndex: 0 };

    componentDidMount() {
        const { i18n } = this.props;
        detectLangParameterAndSetOnBody(i18n);
    }

    onExiting = () => {
        this.animating = true;
    };

    onExited = () => {
        this.animating = false;
    };

    next = () => {
        if (this.animating) return;
        const nextIndex =
            this.state.activeIndex === previewImagesNumber - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    };

    previous = () => {
        if (this.animating) return;
        const nextIndex =
            this.state.activeIndex === 0 ? previewImagesNumber - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    };

    renderQRCodeBaseOnHost = () => {
        const isChina = isChinaVersionUrl();
        if (isChina) return <img src={QRCodeCN} alt="QRCodeCN" />;
        return <img src={QRCodeGeneral} alt="QRCodeGeneral" />;
    };

    render() {
        const { activeIndex } = this.state;
        const { t, i18n } = this.props;
        const previewImages = renderWallpaperImage(i18n.language);
        const sentences = t("desktopopen_caption").split("\n");

        return (
            <div className="desktopPage">
                <img
                    className="desktopPage-companyLogo"
                    src={CompanyLogo}
                    alt="CompanyLogo"
                    onClick={() => window.open("https://www.seekrtech.com/")}
                />
                <img className="desktopPage-phone" src={Phone} alt="Phone" />
                <Carousel
                    className="desktopPage-carousel"
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                    interval="3000"
                    ride="carousel"
                >
                    {previewImages.map((previewImage, index) => (
                        <CarouselItem
                            className="desktopPage-carousel-item"
                            onExiting={this.onExiting}
                            onExited={this.onExited}
                            key={previewImage}
                        >
                            <img src={previewImage} alt={`Background${index + 1}`} />
                        </CarouselItem>
                    ))}
                </Carousel>
                <div className="desktopPage-row">
                    <div className="desktopPage-row-logoAndForest" onClick={openForestHomePage}>
                        <img src={AppLogo} alt="AppLogo" />
                        {i18n.language === "zh-TW" ? "Forest 專注森林" : "Forest"}
                    </div>
                    <div className="desktopPage-row-yellowMainName">
                        {renderYellowMainName(i18n.language)}
                    </div>
                    <div className="desktopPage-row-text">
                        {sentences.map((sentence, index) => (
                            <p key={index}>{sentence}</p>
                        ))}
                    </div>
                    <div className="desktopPage-row-qrcode">{this.renderQRCodeBaseOnHost()}</div>
                </div>
                {/* <ChangeLanguage /> */}
                <div className="icp">
                    <a href="http://beian.miit.gov.cn/" target="_blank">
                        沪ICP备18039713号
                    </a>
                </div>
            </div>
        );
    }
}

DesktopPage.propTypes = {
    i18n: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(DesktopPage);
