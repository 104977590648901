import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import './WorkingArea.scss';

import { toSelectObject, changeModeToEdit } from '../../states/actions';
import { clearAllObjectSelectStyle } from '../../lib';

const WorkingArea = ({
  currentTool,
  background,
  toSelectObject: toSelectObjectFromProps,
  changeModeToEdit: changeModeToEditFromProps,
}) => (
  <div id="workingAreaWrapper">
    <div
      id="workingAreaID"
      className={`workingArea-${currentTool}`}
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
      }}
      onClick={event => {
        if (event.target === event.currentTarget) {
          clearAllObjectSelectStyle(toSelectObjectFromProps);
          if (currentTool === 'TEXT') changeModeToEditFromProps(false);
        }
      }}
    />
  </div>
);

WorkingArea.propTypes = {
  background: PropTypes.string.isRequired,
  changeModeToEdit: PropTypes.func.isRequired,
  currentTool: PropTypes.string.isRequired,
  toSelectObject: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { toSelectObject, changeModeToEdit }
)(WorkingArea);
