import { SELECT_BACKGROUND } from '../actionTypes';

const initialState = {
  selectBackgroundId: 0,
};

const background = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_BACKGROUND: {
      const { id } = action.payload;
      return {
        ...state,
        selectBackgroundId: id,
      };
    }
    default:
      return state;
  }
};

export default background;
