import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';

import { toSelectObject } from '../../states/actions';
import { copyStickerAndAppend, changeStickerThemeButtonStyle } from '../../lib';
import stickerTools from '../stickerTools';
import './MoreStickersModal.scss';
import commonCloseWhiteIcon from '../../assets/icon/common_close_white.png';
import themeStickerIcon1 from '../../assets/icon/theme/theme_sticker_icon_1.png';
import themeStickerIcon2 from '../../assets/icon/theme/theme_sticker_icon_2.png';
import themeStickerIcon3 from '../../assets/icon/theme/theme_sticker_icon_3.png';

const renderStickersThemeById = (
  toSelectObjectFromProps,
  selectWhichThemeToShowId,
  setStickersTheme,
  toggle
) =>
  stickerTools[selectWhichThemeToShowId].map(stickerTool => (
    <Col xs={3} key={stickerTool.id}>
      <div
        id={stickerTool.id}
        className="moreStickersModal-body-stickers-each"
        theme={selectWhichThemeToShowId}
        onClick={() => {
          copyStickerAndAppend(stickerTool.id, toSelectObjectFromProps);
          setStickersTheme(selectWhichThemeToShowId);
          toggle();
        }}
      >
        <stickerTool.source width="100%" height="100%" />
      </div>
    </Col>
  ));

const MoreStickersModal = ({
  isOpen,
  toggle,
  setStickersTheme,
  selectWhichThemeToShowId,
  selectWhichThemeToShow,
  toSelectObject: toSelectObjectFromProps,
  t,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    className="moreStickersModal"
    contentClassName="moreStickersModal-content"
    onOpened={() => {
      changeStickerThemeButtonStyle(selectWhichThemeToShowId);
    }}
  >
    <ModalBody className="moreStickersModal-body">
      <div className="moreStickersModal-body-closeAction" onClick={toggle}>
        <img src={commonCloseWhiteIcon} alt="commonCloseWhiteIcon" />
      </div>
      <Container>
        <Row className="justify-content-center align-items-center moreStickersModal-body-header">
          <Col xs={6}>{t('main_button_sticker')}</Col>
        </Row>
        <Row>
          <Col xs={12} className="moreStickersModal-body-categories">
            <span
              className="stickerCategory category1"
              onClick={() => selectWhichThemeToShow(0)}
            >
              <img src={themeStickerIcon1} alt="themeStickerIcon1" />
              {t('stickerpack_title_theme1')}
            </span>
            <span
              className="stickerCategory category2"
              onClick={() => selectWhichThemeToShow(1)}
            >
              <img src={themeStickerIcon2} alt="themeStickerIcon2" />
              {t('stickerpack_title_theme2')}
            </span>
            <span
              className="stickerCategory category3"
              onClick={() => selectWhichThemeToShow(2)}
            >
              <img src={themeStickerIcon3} alt="themeStickerIcon3" />
              {t('stickerpack_title_theme3')}
            </span>
          </Col>
          <Row className="moreStickersModal-body-stickers">
            {renderStickersThemeById(
              toSelectObjectFromProps,
              selectWhichThemeToShowId,
              setStickersTheme,
              toggle
            )}
          </Row>
        </Row>
      </Container>
    </ModalBody>
  </Modal>
);

MoreStickersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectWhichThemeToShow: PropTypes.func.isRequired,
  selectWhichThemeToShowId: PropTypes.number.isRequired,
  setStickersTheme: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toSelectObject: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(
    () => ({}),
    { toSelectObject }
  )(MoreStickersModal)
);
