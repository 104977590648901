const textTools = [
  { id: 0, source: '#000000' },
  { id: 1, source: '#4D4D4D' },
  { id: 2, source: '#CCCCCC' },
  { id: 3, source: '#FFFFFF' },
  { id: 4, source: '#604A3A' },
  { id: 5, source: '#CC3D3D' },
  { id: 6, source: '#F2A455' },
  { id: 7, source: '#F2D649' },
  { id: 8, source: '#7DB347' },
  { id: 9, source: '#47B3A1' },
  { id: 10, source: '#3674B3' },
  { id: 11, source: '#7D47B3' },
  { id: 12, source: '#E6CCFF' },
  { id: 13, source: '#CCE6FF' },
  { id: 14, source: '#B8E6DE' },
  { id: 15, source: '#CFE6B8' },
  { id: 16, source: '#FFF2B3' },
  { id: 17, source: '#FFDFBF' },
  { id: 18, source: '#FFCCCC' },
  { id: 19, source: '#CC9D7B' },
];

export default textTools;
