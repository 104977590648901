import { SELECT_OBJECT } from '../actionTypes';

const initialState = {
  selectObjectId: '',
};

const tool = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_OBJECT: {
      const { id } = action.payload;
      return {
        ...state,
        selectObjectId: id,
      };
    }
    default:
      return state;
  }
};

export default tool;
