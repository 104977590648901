import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

// import ChangeLanguage from '../Languages/ChangeLanguage';
import renderWallpaperImage from "../Languages/RenderWallpaperImage";
import renderMainName from "../Languages/RenderMainName";
import ResultPage from "../ResultPage";
import AppLogo from "../../assets/other/app_logo.png";

import "./index.scss";
import {
    setBackgroundColorToGreen,
    detectLangParameterAndSetOnBody,
    openForestHomePage
} from "../../lib/other";

class HomePage extends Component {
    state = { wallpaperId: "" };

    componentDidMount() {
        const { i18n } = this.props;
        const urlParams = new URLSearchParams(window.location.search);
        const wallpaperId = urlParams.get("id") || "";
        this.setState({ wallpaperId });
        setBackgroundColorToGreen(true);
        detectLangParameterAndSetOnBody(i18n);

        window.dataLayer.push({ event: "page_main" });
    }

    render() {
        const { t, i18n } = this.props;
        const { wallpaperId } = this.state;
        const previewImages = renderWallpaperImage(i18n.language);

        return (
            <div className="homePage">
                {wallpaperId ? (
                    <ResultPage />
                ) : (
                    <Fragment>
                        <div className="homePage-logoAndForest">
                            <img
                                src={AppLogo}
                                alt="AppLogo"
                                className="homePage-forestLink"
                                onClick={openForestHomePage}
                            />
                            <span className="homePage-forestLink" onClick={openForestHomePage}>
                                {i18n.language === "zh-TW" ? "Forest 專注森林" : "Forest"}
                            </span>
                        </div>
                        <div className="homePage-title">{renderMainName(i18n.language)}</div>
                        {previewImages.map((previewImage, index) => (
                            <img
                                src={previewImage}
                                key={index}
                                alt={`Theme${index + 1}Preview`}
                                className={`homePage-wallpaper${index + 1}`}
                            />
                        ))}
                        <Container className="homePage-bottomRow">
                            <Row className="justify-content-center align-items-center">
                                <Col xs={6}>
                                    <NavLink to="/select-wallpaper">
                                        <button
                                            id="homePage-startButton"
                                            type="button"
                                            className="homePage-bottomRow-button"
                                        >
                                            {t("landingpage_button_start")}
                                        </button>
                                    </NavLink>
                                </Col>
                            </Row>
                        </Container>
                        {/* <ChangeLanguage /> */}
                    </Fragment>
                )}
                <div className="icp--in-app">
                    <a href="http://beian.miit.gov.cn/" target="_blank">
                        沪ICP备18039713号
                    </a>
                </div>
            </div>
        );
    }
}

HomePage.propTypes = {
    i18n: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(HomePage);
